import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import Layout from './layouts/Master.vue';

Vue.use(VueRouter);

const routes = [
    { path: '*', redirect: '/market' },
    {
        path: '/',
        component: Layout,
        redirect: '/account',
        children: [
            {
                path: 'member',
                name: 'Member',
                component: () => import('@/components/account/Member.vue'),
                meta: {
                    authRequired: true,
                    title: 'User Referral Link',
                },
            },
            {
                path: 'vesting',
                name: 'Vesting',
                component: () => import('@/router/views/Presale.vue'),
                meta: {
                    title: 'Vesting',
                },
            },
            {
                path: 'account',
                name: 'Account',
                component: () => import('./views/Account/Index.vue'),
                meta: {
                    authRequired: true,
                    title: 'Information Account',
                },
                redirect: { name: 'Wallet' },
                children: [
                    {
                        path: 'wallet',
                        name: 'Wallet',
                        component: () =>
                            import('@/components/account/Wallet.vue'),
                        meta: {
                            authRequired: true,
                            title: 'User Account',
                        },
                    },
                ],
            },
            {
                path: 'login',
                name: 'Login',
                component: () => import('./views/Auth/Login.vue'),
                meta: {
                    title: 'Login Marketplace',
                },
            },
            {
                path: 'register',
                name: 'Register',
                component: () => import('./views/Auth/Register.vue'),
                meta: {
                    title: 'Register Marketplace',
                },
            },
        ],
    },
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        return { x: 0, y: 0 };
    },
    linkActiveClass: 'active',
    linkExactActiveClass: 'active-children',
});
router.beforeEach((routeTo, routeFrom, next) => {
    const publicPages = ['/login', '/register', '/forgot', '/resend'];
    const authpage = publicPages.includes(routeTo.path);
    const isLogin = store.getters['auth/loggedIn'];
    if (routeTo.matched.some((m) => m.meta.authRequired)) {
        if (isLogin) {
            next();
        } else {
            next({ name: 'Login' });
        }
    } else if (isLogin) {
        if (authpage) {
            next({ name: 'Account', query: { tab: 'Member' } });
        } else {
            next();
        }
    } else {
        next();
    }
});
export default router;
