<script>
import { mapGetters } from 'vuex';
import { filter } from 'lodash';
// import { QrcodeStream } from 'vue-qrcode-reader';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

export default {
    // components: {  QrcodeStream },
    data() {
        return {
            listTab: [
                { name: 'Account', id: 1, short: 'Wallet', img: 'account.svg' },
                {
                    name: 'Referral link',
                    id: 4,
                    short: 'Member',
                    img: 'referral.svg',
                },
                {
                    breaking: true,
                    id: 101,
                    short: 'br',
                },
                {
                    breaking: true,
                    id: 103,
                    short: 'br',
                },
            ],
            width: 1920,
            showComponents: true,
            showMenuUser: true,
            // isUpdateAvatar: false,
            // isChose: '',
            // qrActive: 1,
            settings: {
                dots: false,
                dotsClass: 'slick-dots custom-dot-class',
                edgeFriction: 0.35,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 5000,
            },
            hidden: '.sa',
            isScrolled: false,
        };
    },
    computed: {
        ...mapGetters({
            UserInfo: 'info/UserInfo',
            QrCode: 'auth/QrCode',
            ListAvatar: 'info/ListAvatar',
        }),
        getTabActive() {
            return this.$route.meta.title;
        },
        AddressShow() {
            const { address } = this.UserInfo;
            if (!address) return null;
            const stringShort = `${address.slice(0, 7)}...${address.slice(
                address.length - 10,
                address.length,
            )}`;
            return stringShort;
        },
        getMenu() {
            // listTab
            const self = this;
            return filter(this.listTab, (data) => {
                return !data.short.includes(self.hidden);
            });
        },
    },

    methods: {
        handleResize() {
            this.width = window.innerWidth;
            if (window.innerWidth <= 1300) {
                this.showMenuUser = false;
            } else {
                this.showMenuUser = true;
            }
        },
        logout() {
            this.$store.commit('auth/LOGOUT_SUCCESS');
        },
        onCopy() {
            this.$toastr.s('Copy Link Referral Success', 'Successfully');
        },
        onError() {
            this.$toastr.e(
                'Copy Link Referral Fail,Please Try Again',
                'Failed',
            );
        },
        // onGetQR() {
        //     this.$store.dispatch('auth/QrCode');
        // },
        // changeAvatar() {
        //     this.$store.dispatch('info/req_postChoseAvatar', {
        //         image: this.isChose,
        //     });
        // },
        onDecode(payload) {
            console.log(payload);
        },
        onScroll() {
            const menu = document.getElementById('menuScroll');
            if (document.body.scrollTop > 90) {
                menu.classList.add('slideToTop');
                menu.classList.remove('sildeToBottom');
            } else {
                this.isScrolled = false;
                menu.classList.remove('slideToTop');
                menu.classList.add('sildeToBottom');
            }
        },
        checkScreen() {
            if (window.innerWidth <= 1300) {
                this.showMenuUser = false;
            }
        },
    },
    mounted() {
        this.handleResize();
        this.checkScreen();
    },
    async created() {
        window.addEventListener('resize', this.handleResize);
        if (window.innerWidth <= 1300) {
            this.showMenuUser = false;
        }
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
};
</script>
<template>
    <div class="account-page">
        <!-- <b-container> -->
        <b-row class="justify-content-center">
            <div class="col-lg-12 col-12 px-0 px-md-2">
                <div class="content-box">
                    <router-view></router-view>
                </div>
            </div>
        </b-row>
        <div class="menu-active" v-if="!showMenuUser">
            <div class="name" v-if="getTabActive">
                {{ getTabActive }}
            </div>
        </div>
    </div>
</template>
<style lang="scss">
input[type='number'] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

.slide-tournament {
    max-width: 275px;
    margin-right: 15px;

    .slick-next {
        display: none;
        right: -13px;
    }

    .slick-prev {
        display: none !important;
    }

    @media (max-width: 575px) {
        width: 100%;
        max-width: 275px;
    }
}

.btn-cls {
    position: absolute;
    bottom: 0;
    right: 0;
    height: max-content;

    button {
        height: 30px;
        width: max-content;
        margin: 0;
        min-width: unset !important;
    }
}

.account-page {
    padding-top: 50px;
    padding-bottom: 50px;
    z-index: 3;

    .menu {
        // position: fixed;
        // top: 90px;
        // height: calc(100vh - 90px);
        left: 0px;
        width: 260px;
    }

    .content-box {
        max-width: 1440px;
        margin-left: 20px;
        margin: auto;
        @media (max-width: 1400px) {
            width: 900px;
        }
        @media (max-width: 1300px) {
            width: 100%;
            margin: 0 auto;
        }
        @media (max-width: 991px) {
            padding: 0 12px;
        }
    }

    .user-box {
        backdrop-filter: blur(19px);
        max-width: 320px;
        min-height: 600px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        left: 0;
        position: relative;
        @media (max-width: 1300px) {
            z-index: 999;
        }
        // overflow: auto;

        @media (max-width: 575px) {
            // max-height: calc(100vh - 80px);
            height: 100vh;
        }
        @media (max-height: 768px) {
            min-height: unset;
            height: 100%;
            max-height: 100vh;
        }

        .close {
            background: #b625f9;
            width: 35px;
            clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
            height: 35px;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: 0;
            color: #000;
            opacity: 1;
            position: absolute;
            right: 10px;
            top: 10px;
            box-shadow: 5px 5px 5px black;
            display: none;
            z-index: 10000;
        }

        .avatar {
            width: 150px;
            height: 150px;
            background: #fff;
            border-radius: 50%;
            margin: 10px auto 0;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px;
            position: relative;

            img {
                object-fit: cover;
                border-radius: 50%;
                height: 100%;
                width: auto;
            }

            .update-avatar {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
                transition: all 0.3s ease-in-out;
                width: 100%;
                height: 100%;
                border: 2px solid #00b1ff;
                border-radius: 50%;
                background: rgba($color: #000000, $alpha: 0.7);
                display: flex;
                align-items: center;
                justify-content: center;

                button {
                    margin: 0 auto;
                    width: 90px;
                    display: flex;
                    justify-content: center;
                    height: 30px;
                    align-items: center;
                    font-size: 18px;
                    font-family: 'HHBI';
                    color: #b625f9;
                    background: #00172a;
                    border: 1px solid #b625f9;
                    border-radius: 10px;
                    padding: 5px;

                    &:hover {
                        background: #b625f9;
                        color: #00172a;
                    }
                }
            }
        }

        .info-user {
            margin: 20px auto 20px;
            text-align: center;

            span {
                text-align: center;
                color: #b625f9;
                font-size: clamp(0.7em, 2.5vw, 1em);
                font-weight: 550;
                margin-bottom: 10px;
                font-family: 'helvetica';
                letter-spacing: 1px;
                display: block;
            }
        }

        .logout,
        .menu-user {
            position: relative;
            padding: 20px 0;
            width: 100%;
            margin: auto;

            // &::before {
            //     content: '';
            //     width: 80%;
            //     height: 1px;
            //     background: #B625F9;
            //     top: 0;
            //     left: 0;
            //     right: 0;
            //     margin: auto;
            //     position: absolute;ư
            // }

            .user-item {
                min-height: 50px;
                display: flex;
                align-items: center;
                text-decoration: none;
                border-bottom: 0.5px solid #2a323a;

                &:hover,
                &.active,
                &:focus {
                    background: rgba(0, 255, 255, 0.2);

                    a {
                        color: #b625f9;
                    }

                    img {
                        filter: opacity(0.5) drop-shadow(0 0 0 #b625f9);
                    }
                }

                &.active {
                    position: relative;

                    &::before {
                        position: absolute;
                        content: '';
                        background: url('~@/assets/images/icons/arrow.svg');
                        background-size: 100% 100%;
                        background-position: center center;
                        background-repeat: no-repeat;
                        top: 0;
                        left: -12px;
                        width: 50px;
                        height: 50px;
                    }
                }

                img {
                    width: 1.2em;
                    margin-right: 15px;
                }

                a {
                    color: #fff;
                    font-weight: 700;
                    font-family: 'helvetica';
                    cursor: pointer;
                    letter-spacing: 1px;
                    font-size: clamp(0.9em, 2.5vw, 1em);
                    text-transform: capitalize;
                }

                button {
                    outline: none;
                    padding: 0 10% 0 20%;
                }
            }

            .break-point {
                background: #b625f9;
                height: 1px;
                width: 100%;
            }
        }

        .logout {
            padding: 0px 0 30px;

            .user-item {
                a {
                    color: #b625f9;
                }
            }
        }

        @media (max-width: 991px) {
            .close {
                display: flex;
            }

            position: fixed;
            right: 0;
            top: 60px;
            width: 100%;
            max-width: 320px;
            bottom: 0;
            z-index: 1040;
            height: max-content;
            &.active {
                top: 0;
                bottom: 0;
                min-height: 100vh;

                .menu-user {
                    overflow-y: auto;
                    // max-height: calc(100vh - 492px);
                    &::-webkit-scrollbar-track {
                        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                        border-radius: 5px;
                        // background-color: #001326;
                    }

                    &::-webkit-scrollbar {
                        border-radius: 5px;
                        width: 5px;
                        background-color: #001326;
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 5px;
                        background-color: #b625f9;
                    }
                }
            }
        }
        @media (max-width: 767px) and (max-height: 800px) {
            position: absolute;
            top: 0;
            bottom: 0;
            min-height: 100%;
            max-height: 600px;
            .address {
                font-size: 15px;
            }
        }
        @media (max-width: 575px) {
            width: 100%;
            top: 0;
            max-width: 100%;
        }
    }

    @media (min-width: 768px) and (max-width: 991px) {
        .container {
            width: 100%;
            max-width: 100%;
        }
    }
    @media (max-width: 767px) {
        .container {
            width: 100%;
            max-width: calc(100% - 10px);
        }
    }

    .back-drop {
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1030;
    }

    .menu-active {
        position: fixed;
        left: 0;
        top: 0;
        @media (min-width: 576px) {
            top: 65px;
        }
        @media (min-width: 992px) {
            top: 90px;
        }

        // z-index: 1040;
        background: #b625f9;
        border-radius: 0;
        height: 40px;
        display: flex;
        min-width: 175px;
        cursor: pointer;
        justify-content: space-around;
        align-items: center;
        padding: 0 20px;

        .icons {
            i,
            svg {
                font-size: 16px;
            }

            margin: 0 10px;
        }

        .name {
            color: #000;
            font-weight: 600;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: capitalize;
        }

        // @media (max-width: 575px) {
        //     top: 5px;
        // }
    }
}

.wallet-address-2 {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    &:before {
        content: '';
        width: 100%;
        height: 1px;
        background: #b625f9;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        position: absolute;
    }

    .address {
        color: #fff;
        font-size: 1.2em;
        font-weight: bold;
        margin-right: 15px;
    }

    a {
        margin: 0 5px;
        cursor: pointer;

        img {
            height: 1.2em;
            width: auto;
        }

        &:hover {
            transform: scale(1.1);
        }
    }
}
</style>
<style lang="scss">
.account-page {
    /* width */
    ::-webkit-scrollbar {
        width: 3px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: none;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #00b3b3;
    }
}

@keyframes sildeToTop {
    from {
        top: 90px;
    }
    to {
        top: 0;
    }
}

@keyframes sildeToBottom {
    from {
        top: 0;
    }
    to {
        top: 90px;
    }
}

.slideToTop {
    animation: sildeToTop 1.2s alternate forwards;

    .user-box {
        height: 100vh;
    }
}

.sildeToBottom {
    animation: sildeToBottom 1.2s alternate forwards;
}
</style>
