<script>
import { mapGetters } from 'vuex';
import Paginate from '@/components/shared/Paginate.vue';
import ICountUp from 'vue-countup-v2';

export default {
    components: {
        Paginate,
        ICountUp,
    },
    data() {
        return {
            search: {
                search: '',
                page: 1,
                address: null,
            },
            fields: [
                {
                    key: 'refId',
                    sortable: false,
                    label: 'Ref Id',
                    class: 'text-center',
                },
                {
                    key: 'address',
                    sortable: false,
                    label: 'Address Wallet',
                    class: 'text-center',
                },
                {
                    key: 'ref',
                    label: 'Referral',
                    sortable: false,
                    class: 'text-center',
                },
                {
                    key: 'createAt',
                    label: 'Registration Date',
                },
            ],
            mobileFields: [
                {
                    key: 'refId',
                    sortable: false,
                    label: 'Ref Id',
                    class: 'text-left',
                },
                {
                    key: 'ref',
                    label: 'Referral',
                    sortable: false,
                    class: 'text-center',
                },
                {
                    key: '#',
                    label: 'Action',
                    class: 'text-center',
                },
            ],
            currentPage: 1,
            totalPages: 10,
            renderComponent: true,
            options2: {
                useEasing: true,
                useGrouping: true,
                separator: ',',
                decimal: '.',
                prefix: '',
                suffix: ' UCON',
                decimalPlaces: 0,
            },
            delay: 2000,
            staticUser: {
                member: 0,
                usdt: 0,
            },
            comQuery: {
                page: 1,
            },
            comFields: [
                {
                    key: 'amount',
                    label: 'Amount',
                    class: 'text-center',
                },
                {
                    key: 'time',
                    label: 'Time',
                    class: 'text-center',
                },
                {
                    key: '#',
                    label: 'Action',
                    class: 'text-center',
                },
            ],
        };
    },
    computed: {
        url() {
            return `${window.location.hostname}/register?sponsor=${this.UserInfo.address}`;
        },
        ...mapGetters({
            UserInfo: 'info/UserInfo',
            Member: 'member/Member',
            CommissionHistory: 'member/CommissionHistory',
        }),
        Statistical() {
            return this.Member.statistical;
        },
        MyReference() {
            return Object.entries(this.Member.refs);
        },
    },
    methods: {
        onCopy() {
            this.$toastr.s('Copy Link Referral Success', 'Successfully');
        },
        CopyAddress(string) {
            if (window.navigator.clipboard) {
                window.navigator.clipboard
                    .writeText(string)
                    .then(() => {
                        this.$toastr.s('Address copied', 'Successfully');
                    })
                    .catch(() => {
                        this.$toastr.e('Failed to copy', 'Error');
                    });
            } else {
                this.$toastr.e('Failed to copy', 'Error');
            }
        },
        onError() {
            this.$toastr.e(
                'Copy Link Referral Fail,Please Try Again',
                'Failed',
            );
        },
        onSearch(type) {
            if (type === 'reset') {
                this.search = {
                    search: '',
                    page: 1,
                };
            }
            this.$store.dispatch('member/req_getListMember', this.search);
            this.forceRerender();
            this.search.page = 1;
        },
        onChangePage(payload) {
            this.search.page = payload;
            this.$store.dispatch('member/req_getListMember', this.search);
        },
        forceRerender() {
            // Remove my-component from the DOM
            this.renderComponent = false;

            this.$nextTick(() => {
                // Add the component back in
                this.renderComponent = true;
            });
        },
        async StatisUser() {
            const { register } = this.$store.state.contract.managers;
            const { address } = this.UserInfo;
            if (!register.cont) return;
            const data = await register.cont.methods
                .countReferral(address)
                .call();
            if (data) {
                console.log(data);
                this.staticUser.member = data;
                this.staticUser.usdt = this.TruncateToDecimals2(
                    parseInt(data, 0) * 2.5,
                    '',
                    4,
                );
            }
        },
        changeComPage(payload) {
            this.comQuery.page = payload;
            this.$store.dispatch(
                'member/req_getCommissionHistory',
                this.comQuery,
            );
        },
    },
    watch: {
        UserInfo: {
            handler() {
                this.$store.dispatch('member/req_getListMember', {
                    // address: newVal.address,
                });
                this.StatisUser();
                this.$store.dispatch('member/req_getStatistical');
                // this.search.address = newVal.address;
                this.$store.dispatch('member/req_getCommissionStat');
                this.$store.dispatch(
                    'member/req_getCommissionHistory',
                    this.comQuery,
                );
            },
        },
    },
    mounted() {
        const self = this;
        if (self.UserInfo && self.UserInfo.address) {
            this.StatisUser();
            this.$store.dispatch('member/req_getListMember', {
                // address: self.UserInfo.address,
            });
            this.$store.dispatch('member/req_getStatistical');
            this.$store.dispatch('member/req_getCommissionStat');
            this.$store.dispatch(
                'member/req_getCommissionHistory',
                this.comQuery,
            );
        }
    },
};
</script>
<template>
    <b-container class="member-tab">
        <div class="statistical">
            <div class="d-flex align-items-center mb-2">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                >
                    <path
                        d="M13.125 20H8.75C7.42392 20 6.15215 19.4732 5.21447 18.5355C4.27678 17.5979 3.75 16.3261 3.75 15C3.75 13.6739 4.27678 12.4021 5.21447 11.4645C6.15215 10.5268 7.42392 10 8.75 10H13.125C13.2908 10 13.4497 9.93415 13.5669 9.81694C13.6842 9.69973 13.75 9.54076 13.75 9.375C13.75 9.20924 13.6842 9.05027 13.5669 8.93306C13.4497 8.81585 13.2908 8.75 13.125 8.75H8.75C7.0924 8.75 5.50269 9.40848 4.33058 10.5806C3.15848 11.7527 2.5 13.3424 2.5 15C2.5 16.6576 3.15848 18.2473 4.33058 19.4194C5.50269 20.5915 7.0924 21.25 8.75 21.25H13.125C13.2908 21.25 13.4497 21.1842 13.5669 21.0669C13.6842 20.9497 13.75 20.7908 13.75 20.625C13.75 20.4592 13.6842 20.3003 13.5669 20.1831C13.4497 20.0658 13.2908 20 13.125 20ZM10 15C10 15.1658 10.0658 15.3247 10.1831 15.4419C10.3003 15.5592 10.4592 15.625 10.625 15.625H19.375C19.5408 15.625 19.6997 15.5592 19.8169 15.4419C19.9342 15.3247 20 15.1658 20 15C20 14.8342 19.9342 14.6753 19.8169 14.5581C19.6997 14.4408 19.5408 14.375 19.375 14.375H10.625C10.4592 14.375 10.3003 14.4408 10.1831 14.5581C10.0658 14.6753 10 14.8342 10 15ZM21.25 8.75H16.875C16.7092 8.75 16.5503 8.81585 16.4331 8.93306C16.3158 9.05027 16.25 9.20924 16.25 9.375C16.25 9.54076 16.3158 9.69973 16.4331 9.81694C16.5503 9.93415 16.7092 10 16.875 10H21.25C22.5761 10 23.8479 10.5268 24.7855 11.4645C25.7232 12.4021 26.25 13.6739 26.25 15C26.25 16.3261 25.7232 17.5979 24.7855 18.5355C23.8479 19.4732 22.5761 20 21.25 20H16.875C16.7092 20 16.5503 20.0658 16.4331 20.1831C16.3158 20.3003 16.25 20.4592 16.25 20.625C16.25 20.7908 16.3158 20.9497 16.4331 21.0669C16.5503 21.1842 16.7092 21.25 16.875 21.25H21.25C22.9076 21.25 24.4973 20.5915 25.6694 19.4194C26.8415 18.2473 27.5 16.6576 27.5 15C27.5 13.3424 26.8415 11.7527 25.6694 10.5806C24.4973 9.40848 22.9076 8.75 21.25 8.75Z"
                        fill="url(#paint0_linear_57_1580)"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_57_1580"
                            x1="2.5"
                            y1="15"
                            x2="27.5"
                            y2="15"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stop-color="#69EACB" />
                            <stop offset="0.48" stop-color="#EACCF8" />
                            <stop offset="1" stop-color="#6654F1" />
                        </linearGradient>
                    </defs>
                </svg>
                <div class="title p-0 pl-2">Referral Link</div>
            </div>
            <div class="ref-link box-input-group">
                <b-form-input
                    v-model.trim="url"
                    readonly
                    class="link-input"
                ></b-form-input>
                <b-button class="copy">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                    >
                        <g clip-path="url(#clip0_57_1540)">
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M6.5 3.5C6.5 2.70435 6.81607 1.94129 7.37868 1.37868C7.94129 0.816071 8.70435 0.5 9.5 0.5L21.5 0.5C22.2956 0.5 23.0587 0.816071 23.6213 1.37868C24.1839 1.94129 24.5 2.70435 24.5 3.5V15.5C24.5 16.2956 24.1839 17.0587 23.6213 17.6213C23.0587 18.1839 22.2956 18.5 21.5 18.5H9.5C8.70435 18.5 7.94129 18.1839 7.37868 17.6213C6.81607 17.0587 6.5 16.2956 6.5 15.5V3.5ZM9.5 2C9.10218 2 8.72064 2.15804 8.43934 2.43934C8.15804 2.72064 8 3.10218 8 3.5V15.5C8 15.8978 8.15804 16.2794 8.43934 16.5607C8.72064 16.842 9.10218 17 9.5 17H21.5C21.8978 17 22.2794 16.842 22.5607 16.5607C22.842 16.2794 23 15.8978 23 15.5V3.5C23 3.10218 22.842 2.72064 22.5607 2.43934C22.2794 2.15804 21.8978 2 21.5 2H9.5ZM3.5 8C3.10218 8 2.72064 8.15804 2.43934 8.43934C2.15804 8.72064 2 9.10218 2 9.5V21.5C2 21.8978 2.15804 22.2794 2.43934 22.5607C2.72064 22.842 3.10218 23 3.5 23H15.5C15.8978 23 16.2794 22.842 16.5607 22.5607C16.842 22.2794 17 21.8978 17 21.5V20H18.5V21.5C18.5 22.2956 18.1839 23.0587 17.6213 23.6213C17.0587 24.1839 16.2956 24.5 15.5 24.5H3.5C2.70435 24.5 1.94129 24.1839 1.37868 23.6213C0.816071 23.0587 0.5 22.2956 0.5 21.5V9.5C0.5 8.70435 0.816071 7.94129 1.37868 7.37868C1.94129 6.81607 2.70435 6.5 3.5 6.5H5V8H3.5Z"
                                fill="white"
                            />
                        </g>
                        <defs>
                            <clipPath id="clip0_57_1540">
                                <rect
                                    width="24"
                                    height="24"
                                    fill="white"
                                    transform="translate(0.5 0.5)"
                                />
                            </clipPath>
                        </defs>
                    </svg>
                    <div
                        class="text"
                        v-clipboard:copy="url"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                    >
                        Copy Link
                    </div>
                </b-button>
            </div>
            <b-row class="overflow-hidden">
                <b-col
                    v-for="item in Member.commissions"
                    :key="item._id"
                    cols="12"
                    sm="4"
                    class="my-3"
                >
                    <div class="box-statistical">
                        <div class="text">
                            <div class="text-title text-center">
                                {{ item._id.replace('_', ' ') }} Commission
                            </div>
                            <div class="text-content">
                                <ICountUp
                                    v-if="!isNaN(item.total)"
                                    :endVal="item.total"
                                    :options="options2"
                                />
                                <ICountUp
                                    v-else
                                    :endVal="0"
                                    :options="options2"
                                />
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <div class="table-statistical">
                <b-row class="w-100 ml-0 table-item">
                    <b-col md="7" class="left">
                        <p>Your Referral's Active</p>
                    </b-col>
                    <b-col md="5" class="right">
                        <p>
                            {{ staticUser.member }}
                        </p>
                    </b-col>
                </b-row>
                <b-row class="w-100 ml-0 table-item">
                    <b-col md="7" class="left">
                        <p>Your F1 Referrals</p>
                    </b-col>
                    <b-col md="5" class="right">
                        <p>
                            {{ Member.totalRefF1 }}
                        </p>
                    </b-col>
                </b-row>
                <b-row class="w-100 ml-0 table-item">
                    <b-col md="7" class="left">
                        <p>Total Amount You Got</p>
                    </b-col>
                    <b-col md="5" class="right">
                        <p>100 UCON</p>
                    </b-col>
                </b-row>
                <b-row class="w-100 ml-0 table-item">
                    <b-col md="7" class="left">
                        <p>Total Amount BNB You Got</p>
                    </b-col>
                    <b-col md="5" class="right">
                        <p>
                            ~{{ staticUser.usdt }}
                            USDT
                        </p>
                    </b-col>
                </b-row>
                <b-row class="w-100 ml-0 table-item">
                    <b-col md="7" class="left">
                        <p>Your total Referrals</p>
                    </b-col>
                    <b-col md="5" class="right">
                        <p>
                            {{ Member.refs.totalRef }}
                        </p>
                    </b-col>
                </b-row>
                <b-row class="w-100 ml-0 table-item">
                    <b-col md="7" class="left">
                        <p>Ref:</p>
                    </b-col>
                    <b-col md="5" class="right">
                        <div class="myRef">
                            <template v-for="(item, index) in MyReference">
                                <div
                                    v-if="item[0] !== 'totalRef'"
                                    class="refItem"
                                    :key="index"
                                >
                                    {{ item[0] }}: <span>{{ item[1] }}</span>
                                </div>
                            </template>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </div>

        <div class="member-list">
            <div
                class="d-flex justify-content-between flex-wrap align-items-start mb-3"
            >
                <div class="d-flex align-items-center">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        class="mr-2"
                    >
                        <path
                            d="M15 6.25C13.8397 6.25 12.7269 6.71094 11.9064 7.53141C11.0859 8.35188 10.625 9.46468 10.625 10.625C10.625 11.7853 11.0859 12.8981 11.9064 13.7186C12.7269 14.5391 13.8397 15 15 15C16.1603 15 17.2731 14.5391 18.0936 13.7186C18.9141 12.8981 19.375 11.7853 19.375 10.625C19.375 9.46468 18.9141 8.35188 18.0936 7.53141C17.2731 6.71094 16.1603 6.25 15 6.25ZM15 8.75C15.4973 8.75 15.9742 8.94754 16.3258 9.29917C16.6775 9.65081 16.875 10.1277 16.875 10.625C16.875 11.1223 16.6775 11.5992 16.3258 11.9508C15.9742 12.3025 15.4973 12.5 15 12.5C14.5027 12.5 14.0258 12.3025 13.6742 11.9508C13.3225 11.5992 13.125 11.1223 13.125 10.625C13.125 10.1277 13.3225 9.65081 13.6742 9.29917C14.0258 8.94754 14.5027 8.75 15 8.75ZM6.875 10C6.0462 10 5.25134 10.3292 4.66529 10.9153C4.07924 11.5013 3.75 12.2962 3.75 13.125C3.75 14.3 4.4125 15.3125 5.3625 15.85C5.8125 16.1 6.325 16.25 6.875 16.25C7.425 16.25 7.9375 16.1 8.3875 15.85C8.85 15.5875 9.2375 15.2125 9.525 14.7625C8.61435 13.5757 8.12209 12.1209 8.125 10.625V10.275C7.75 10.1 7.325 10 6.875 10ZM23.125 10C22.675 10 22.25 10.1 21.875 10.275V10.625C21.875 12.125 21.3875 13.575 20.475 14.7625C20.625 15 20.7875 15.1875 20.975 15.375C21.5514 15.9339 22.3221 16.2475 23.125 16.25C23.675 16.25 24.1875 16.1 24.6375 15.85C25.5875 15.3125 26.25 14.3 26.25 13.125C26.25 12.2962 25.9208 11.5013 25.3347 10.9153C24.7487 10.3292 23.9538 10 23.125 10ZM15 17.5C12.075 17.5 6.25 18.9625 6.25 21.875V23.75H23.75V21.875C23.75 18.9625 17.925 17.5 15 17.5ZM5.8875 18.1875C3.475 18.475 0 19.7 0 21.875V23.75H3.75V21.3375C3.75 20.075 4.6125 19.025 5.8875 18.1875ZM24.1125 18.1875C25.3875 19.025 26.25 20.075 26.25 21.3375V23.75H30V21.875C30 19.7 26.525 18.475 24.1125 18.1875ZM15 20C16.9125 20 19.05 20.625 20.2875 21.25H9.7125C10.95 20.625 13.0875 20 15 20Z"
                            fill="url(#paint0_linear_57_1695)"
                        />
                        <defs>
                            <linearGradient
                                id="paint0_linear_57_1695"
                                x1="0"
                                y1="15"
                                x2="30"
                                y2="15"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#69EACB" />
                                <stop offset="0.48" stop-color="#EACCF8" />
                                <stop offset="1" stop-color="#6654F1" />
                            </linearGradient>
                        </defs>
                    </svg>
                    <div class="title p-0">Referral List</div>
                </div>
                <div class="search-member">
                    <b-form-input
                        v-model="search.search"
                        placeholder="Enter Wallet Address Here"
                    ></b-form-input>
                    <b-button @click="onSearch">
                        <div class="icons">
                            <img
                                src="~@/assets/images/icons/search.png"
                                alt=""
                            />
                        </div>
                        Search
                    </b-button>
                </div>
            </div>

            <div class="d-none d-lg-block table-responsive">
                <b-table
                    striped
                    hover
                    :items="Member.list"
                    :fields="fields"
                    show-empty
                    thead-class="customer-header"
                >
                    <template #empty>
                        <h4 class="text-center my-4">No Referral</h4>
                    </template>
                    <template #cell(refId)="data">
                        <span>
                            {{ data.value }}
                        </span>
                    </template>
                    <template #cell(address)="data">
                        <span>
                            {{ data.item.address }}
                        </span>
                    </template>
                    <template #cell(ref)="data">
                        <span> F{{ data.item.ref }} </span>
                    </template>
                    <template #cell(createAt)="data">
                        <span>
                            {{ getDateTime3(data.item.createdAt) }}
                        </span>
                    </template>
                </b-table>
            </div>
            <div class="d-lg-none table-responsive mobile">
                <b-table
                    :items="Member.list"
                    :fields="mobileFields"
                    show-empty
                    striped
                    thead-class="customer-header"
                >
                    <template #cell(#)="row">
                        <b-button
                            size="sm"
                            variant="info"
                            @click="row.toggleDetails"
                            class="px-2 py-0"
                        >
                            {{ row.detailsShowing ? 'Hide' : 'Show' }}
                        </b-button>
                    </template>
                    <template #cell(ref)="data">
                        F{{ data.item.ref }}
                    </template>
                    <template #row-details="{ item }">
                        <div class="details">
                            <div class="d-flex justify-content-between">
                                <div>Address:</div>
                                <button
                                    class="address"
                                    @click="CopyAddress(item.address)"
                                >
                                    {{ item.address }}
                                </button>
                            </div>
                            <div class="d-flex justify-content-between">
                                <div>Time of creation:</div>
                                <div>
                                    {{ getDateTime3(item.createdAt) }}
                                </div>
                            </div>
                        </div>
                    </template>
                </b-table>
            </div>
            <div class="table-paginate">
                <Paginate
                    @current="onChangePage"
                    :totalPages="Member.total"
                    v-if="renderComponent"
                />
            </div>
        </div>
        <div class="com=history">
            <div class="title">Commission History</div>
            <div class="table-responsive" v-if="CommissionHistory.results">
                <b-table
                    striped
                    hover
                    :items="CommissionHistory.results"
                    :fields="comFields"
                    show-empty
                    thead-class="customer-header"
                >
                    <template #empty>
                        <h4 class="text-center my-4">No Referral</h4>
                    </template>
                    <template #cell(time)="data">
                        <span>
                            {{ getDateTime3(data.value) }}
                        </span>
                    </template>
                    <template #cell(amount)="data">
                        {{ data.value }} UCON
                    </template>
                    <template #cell(comment)="data">
                        <div class="comment">
                            {{ data.value }}
                        </div>
                    </template>
                    <template #cell(#)="row">
                        <b-button
                            size="sm"
                            variant="info"
                            @click="row.toggleDetails"
                            class="px-2 py-0"
                        >
                            {{ row.detailsShowing ? 'Hide' : 'Show' }}
                        </b-button>
                    </template>
                    <template #row-details="{ item }">
                        <div class="px-2 text-center">
                            {{ item.comment }}
                        </div>
                    </template>
                </b-table>
            </div>
            <div class="table-paginate">
                <Paginate
                    v-if="CommissionHistory.results"
                    @current="changeComPage"
                    :totalPages="CommissionHistory.totalPages"
                />
            </div>
        </div>
    </b-container>
</template>
<style lang="scss">
.member-tab {
    .header-icon {
        max-width: 55px;
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: auto;
            object-fit: scale-down;
        }
    }

    .title {
        font-family: 'Poppins';
        font-size: clamp(1.2em, 4vw, 1.8em);
        padding-bottom: 10px;
        position: relative;
        letter-spacing: 0.6px;
        width: max-content;
        // margin: 0 0 25px;
        font-weight: 600;
        background: var(
            --header-1,
            linear-gradient(90deg, #69eacb 0%, #eaccf8 48%, #6654f1 100%)
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .search-member {
        max-width: 467px;
        height: 48px;
        width: 100%;
        border-radius: 128px;
        padding: 0 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: auto;
        gap: 10px;

        input {
            outline: none;
            border: none;
            height: 40px;
            width: 90%;
            border-radius: 12px;
            background: rgba(141, 141, 141, 0.29);
            color: #fff;

            &:focus {
                box-shadow: none;
            }
        }

        button {
            border-radius: 100vw;
            background: linear-gradient(50deg, #5a41bd 14.98%, #d629f2 106.07%);
            box-shadow: 0px 2px 11.6px 1px rgba(176, 176, 176, 0.25) inset;
            display: flex;
            gap: 5px;
        }
    }

    .box-input-group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .link-input {
            border: none;
            max-width: 570px;
            width: 100%;
            height: 42px;
            background: rgba(141, 141, 141, 0.29);
            // opacity: 0.5;
            border-radius: 12px;
            outline: none;
            box-shadow: none;
        }

        .copy {
            background-image: linear-gradient(
                50deg,
                #5a41bd 14.98%,
                #d629f2 106.07%
            );
        }

        svg {
            margin-right: 5px;
        }

        input {
            outline: none;
            border: 1px solid #b625f9;
            background: linear-gradient(180deg, #00172a 0%, #00000b 100%);
            height: 40px;
            width: 80%;
            border-radius: 0;
            color: #fff;
            border: none;
        }

        button {
            max-width: 170px;
            width: 100%;
            height: 42px;
            margin-left: 2em;
            margin-right: auto;
            border-radius: 267px;
            background: rgba(0, 255, 255, 0.15);
            font-weight: 600;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: capitalize;
            border: none;
        }
    }

    .table-responsive {
        .customer-header {
            background-color: transparent;
            text-align: center;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;

            color: #a6a6a6;
        }

        .table th,
        .table td {
            border: none;
        }

        table {
            thead {
                tr {
                    th {
                        white-space: nowrap;
                    }
                }
            }

            tbody {
                tr {
                    height: 70px;
                    border-radius: 12px;
                    background: rgba(62, 18, 96, 0.46);
                    &:nth-child(odd) {
                        background: rgba(62, 18, 96, 1);
                    }

                    td {
                        background: none;
                        height: 100%;
                        padding: 0.75rem;
                        vertical-align: middle;
                        color: #fff;
                        font-weight: 500;

                        > span {
                            height: 70px;
                            display: flex;
                            padding: 0 0.75rem;
                            align-items: center;
                            margin-bottom: 0;
                            justify-content: center;
                            backdrop-filter: blur(15.899999618530273px);
                        }
                    }
                }
            }
        }
        &.mobile {
            tbody {
                tr {
                    td {
                        .details {
                            padding: 0.5rem;
                            .address {
                                max-width: 200px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                background-color: #86198f;
                                color: #fff;
                                border-radius: 7px;
                                transition: 0.2s;
                                &:active {
                                    background-color: #4a044e;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .table-statistical {
        margin: 35px auto;
        border-radius: 22px;
        border: 1px solid #8500af;
        background: var(
            --13434,
            linear-gradient(
                50deg,
                rgba(90, 65, 189, 0.2) 14.98%,
                rgba(214, 41, 242, 0.2) 106.07%
            )
        );
        backdrop-filter: blur(44.29999923706055px);

        .table-item {
            border-radius: 1px;
            min-height: 75px;
            padding: 0 0.75rem;
            vertical-align: middle;
            color: #fff;
            font-weight: 600;
            display: flex;
            font-family: 'Poppins';
            justify-content: space-between;

            p {
                height: 100%;
                margin-bottom: 0;
                width: 95%;
                display: flex;
                align-items: center;
            }

            .left,
            .right {
                padding: 0%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .left {
                p {
                    padding-left: 2rem;
                }
            }

            .right {
                p {
                    justify-content: center;
                    color: #b625f9;
                }
            }

            @media (min-width: 768px) and (max-width: 991px) {
                flex-direction: column;
                min-height: none;
                height: auto;
                padding: 5px 0;
            }
            @media (max-width: 575px) {
                flex-direction: column;
                min-height: none;
                height: auto;
                padding: 5px 0;
                .right,
                .left {
                    flex: unset;
                    width: 100%;
                    justify-content: center;
                    border: 0;
                }
            }
        }
        .myRef {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
            padding: 8px 15px;
            @media (min-width: 992px) {
                padding: 0px;
            }
            .refItem {
                text-transform: uppercase;
                background-image: linear-gradient(
                    50deg,
                    #5a41bd 14.98%,
                    #d629f2 106.07%
                );
                border-radius: 100vw;
                padding: 2px 12px;
                font-family: 'HHBI';
                color: #e4e4e7;
                span {
                    color: white;
                    font-size: 1.2em;
                }
            }
        }
    }

    .box-statistical {
        // background: linear-gradient(to right, #18d4e2, #a506f9);
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 140px;
        height: 100%;
        outline: none;
        width: 100%;
        max-width: 427px;
        padding: 15px;
        position: relative;
        margin: 0 auto;
        border-radius: 22px;
        border: 1px solid #8500af;
        background: var(
            --13434,
            linear-gradient(
                50deg,
                rgba(90, 65, 189, 0.2) 14.98%,
                rgba(214, 41, 242, 0.2) 106.07%
            )
        );
        backdrop-filter: blur(44.29999923706055px);

        .icons {
            flex: 0 0 70px;
            width: 70px;
            height: 100%;

            img {
                width: 50px;
                height: auto;
            }
        }

        .text {
            flex: 1 1 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 100%;
            color: #ffffff;
            z-index: 1;

            .text-title {
                font-size: 1.4em;
                line-height: 120%;
                font-style: italic;
                font-weight: 600;
                margin-bottom: 15px;
                text-transform: capitalize;
            }

            .text-content {
                font-size: 1.4em;
                line-height: 120%;
                color: #b625f9;
                font-weight: 700;
            }
        }
    }
}
</style>
