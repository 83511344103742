<template>
    <div id="FooterView" class="mt-4">
        <b-container>
            <b-row class="ml-0 w-100">
                <b-col xl="3" md="6" class="py-3">
                    <div class="d-flex align-items-center" data-aos="fade-up">
                        <div class="home-dot">
                            <img
                                src="../../assets/images/icons/footer-dot.png"
                                alt=""
                            />
                        </div>
                        <p class="footer-title m-0 ml-3">OUR ECOSYSTEM</p>
                    </div>
                    <b-row class="ml-0 w-100 mt-3 mt-md-4">
                        <b-col
                            v-for="(item, idx) in col_1"
                            :key="item + idx"
                            cols="6"
                            class="p-0"
                        >
                            <p class="col1-p my-2" data-aos="fade-up">
                                {{ item.title }}
                            </p>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xl="2" md="6" class="mt-3 mt-lg-0 px-2 py-3">
                    <div class="d-flex align-items-center" data-aos="fade-up">
                        <div class="home-dot">
                            <img
                                src="~@/assets/images/icons/footer-dot.png"
                                alt=""
                            />
                        </div>
                        <p class="footer-title m-0 ml-3">CONTACT US</p>
                    </div>
                    <div
                        class="mini-line my-3 my-md-4"
                        data-aos="zoom-in"
                    ></div>
                    <a
                        href="mailto:support@ucon.social"
                        data-aos="zoom-in"
                        class="linked-email"
                    >
                        support@ucon.social
                    </a>
                </b-col>
                <b-col
                    xl="7"
                    class="p-2 d-flex flex-column justify-content-center"
                >
                    <b-row
                        class="ml-0 w-100 justify-content-between px-lg-5 px-3 other-links"
                    >
                        <div
                            v-for="(item, idx) in col_3"
                            :key="item + idx"
                            class="flex-grow-1"
                        >
                            <a
                                :href="item.link"
                                class="col_3a"
                                data-aos="fade-up"
                            >
                                {{ item.title }}
                            </a>
                        </div>
                    </b-row>
                    <b-row class="ml-0 w-100 align-items-center pt-5">
                        <b-col cols="8">
                            <div
                                class="p-0 d-flex flex-wrap justify-content-center"
                            >
                                <a
                                    v-for="(item, idx) in social"
                                    :key="idx + item"
                                    :href="item.link"
                                    target="_blank"
                                    class="social-link mx-lg-4 mx-2 mx-sm-1 mx-md-2 my-1"
                                >
                                    <img :src="item.src" alt="" />
                                </a>
                            </div>
                        </b-col>
                        <b-col cols="4 d-flex justify-content-end">
                            <div class="footer-logo">
                                <img
                                    src="~@/assets/images/logo/logo.png"
                                    alt=""
                                    draggable="false"
                                />
                            </div>
                        </b-col>
                    </b-row>
                    <p class="reserved text-right pt-4 mt-3 px-2">
                        © 2024, UCON Social. All rights reserved.
                    </p>
                </b-col>
            </b-row>
            <div v-if="false" class="divide-line"></div>
        </b-container>
    </div>
</template>

<script>
export default {
    name: 'FooterView',
    data() {
        return {
            scrollProgress: 0,
            col_1: [
                {
                    title: 'UCON Social',
                },
                {
                    title: 'UCON Chain',
                },
                {
                    title: 'UCON Wallet',
                },
                {
                    title: 'UCON Payment',
                },

                {
                    title: 'UCON Marketplace',
                },
            ],
            col_3: [
                {
                    title: 'White Paper',
                    line: true,
                    link: 'https://ucon-social.gitbook.io/ucon-whitepaper/tokenomic/ucon-tokenomic',
                },
                {
                    title: 'UCON Token',
                    line: true,
                    link: 'https://bscscan.com/token/0x1a8ae40f498aed05e3647933fbdad54ecfd2134b',
                },
                {
                    title: 'Token Audit',
                    line: true,
                    link: 'https://app.solidproof.io/projects/ucon-social',
                },
                {
                    title: 'Trade Mining',
                    line: true,
                    link: 'https://ucon.social/#trade-mining',
                },

                // {
                //     title: 'Terms of Service',
                //     line: true,
                //     link: 'https://herobook.io/term-of-service',
                // },
                // {
                //     title: 'Privacy Policy',
                //     line: false,
                //     link: 'https://herobook.io/privacy-policy',
                // },
            ],
            social: [
                {
                    // eslint-disable-next-line global-require
                    src: require('../../assets/images/media/telegram.svg'),
                    link: 'https://t.me/ucongroup',
                },
                {
                    // eslint-disable-next-line global-require
                    src: require('../../assets/images/media/discord.svg'),
                    link: 'https://discord.gg/xETJPVsFe9',
                },
                {
                    // eslint-disable-next-line global-require
                    src: require('../../assets/images/media/medium.svg'),
                    link: 'https://medium.com/@social_8217',
                },
                {
                    // eslint-disable-next-line global-require
                    src: require('../../assets/images/media/instagram.svg'),
                    link: 'https://www.instagram.com/ucon_socialfi/',
                },
                {
                    // eslint-disable-next-line global-require
                    src: require('../../assets/images/media/tiktok.svg'),
                    link: 'https://www.tiktok.com/@ucon.socialfi?_t=8jXoxGeh3P5&_r=1',
                },
                {
                    // eslint-disable-next-line global-require
                    src: require('../../assets/images/media/youtube.svg'),
                    link: 'https://www.youtube.com/@UconSocialFi',
                },
                {
                    // eslint-disable-next-line global-require
                    src: require('../../assets/images/media/twitter.svg'),
                    link: 'https://twitter.com/Ucon_SocialFi',
                },
                {
                    // eslint-disable-next-line global-require
                    src: require('../../assets/images/media/galxe.png'),
                    link: 'https://galxe.com/id/0xbD9ebC220f45f6C917eD6B72C2FE22Ec0924017A',
                },
                {
                    // eslint-disable-next-line global-require
                    src: require('../../assets/images/media/facebook.svg'),
                    link: 'https://www.facebook.com/profile.php?id=61556004321324',
                },
            ],
        };
    },
    computed: {
        isActive() {
            return this.scrollProgress > 10;
        },
        scrollPercent() {
            return (
                308 -
                (this.scrollProgress /
                    (document.body.scrollHeight - window.innerHeight)) *
                    308
            );
        },
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    unmounted() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleClick() {
            window.scrollTo(0, 0);
        },
        handleScroll() {
            this.scrollProgress = window.scrollY;
        },
    },
};
</script>
<style lang="scss" scoped>
#FooterView {
    font-family: 'Poppins';
    padding: 4rem 0;
    background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.2) 0%,
            rgba(0, 0, 0, 0.2) 100%
        ),
        rgba(64, 11, 131, 0.6);
    backdrop-filter: blur(7px);
    @media (max-width: 991px) {
        padding: 3rem 0;
    }
    @media (max-width: 768px) {
        padding: 2rem 0;
    }

    .col-to-top {
        display: flex;
        justify-content: center;
        @media (max-width: 575px) {
            justify-content: flex-end;
        }
    }

    p.reserved {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        color: #dcdcdc !important;
        margin: 0;
    }

    .linked-email {
        text-decoration: none;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        color: #dcdcdc;
    }

    button {
        width: 50px;
        height: 50px;
        border: none;
        outline: none;
        position: relative;
        cursor: pointer;
        background: linear-gradient(to right, #18d4e2, #a506f9);

        &:hover {
            background-size: 200%;
            animation: loop 1s infinite linear;
        }

        img {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: scale-down;
        }

        @media (max-width: 991px) {
            width: 35px;
            height: 35px;
        }

        &::before {
            content: '';
            position: absolute;
            top: 1px;
            right: 1px;
            bottom: 1px;
            left: 1px;
            background: linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.2),
                    rgba(0, 0, 0, 0.2)
                ),
                #353f48;
        }
    }

    .footer-logo {
        max-width: 169px;
        width: 100%;
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 768px) {
            max-width: 139px;
            width: 100%;
        }

        img {
            width: 100%;
            height: auto;
            object-fit: scale-down;
        }
    }

    .social-link {
        width: 40px;
        height: 40px;
        @media (max-width: 768px) {
            width: 30px;
            height: 30px;
        }
        display: block;
        line-height: 0;
        background-image: linear-gradient(45deg, #5a41bd, #d629f2);
        border-radius: 100vw;
        padding: 8px;
        img {
            width: 100%;
            height: 100%;
        }
    }

    .divide-line {
        height: 1px;
        width: 100%;
        background: #353f48;
        margin: 3rem 0;
        @media (max-width: 991px) {
            margin: 2rem 0;
        }
    }

    .footer-title {
        font-weight: 600;
        color: #f8f8f8;
    }

    .col1-p {
        color: #dcdcdc;
        font-size: clamp(14px, 1.5vw, 16px);
    }

    .mini-line {
        height: 0.5px;
        width: 35px;
        background: #dcdcdc;
    }

    .col_3a {
        font-weight: 400;
        color: #f0f0f0;
        display: block;
    }

    .col-line {
        width: 1px;
        background: #f0f0f0;
        height: 100%;
    }

    .col_3p {
        font-weight: 400;
        color: #dcdcdc;
    }

    .boder-left-footer {
        position: relative;

        .boder-left-footer-line {
            position: absolute;
            right: -35px;
            width: 1px;
            height: 20px;
            background: #f8f8f8;
        }
    }
}
.other-links {
    > div {
        position: relative;
        padding: 0px 5px;
        flex-basis: 100%;
        @media (min-width: 576px) {
            text-align: center;
            border-left: 1px solid #f8f8f8;
            flex-basis: auto;
        }
        &:first-child {
            border-left: none;
        }
    }
}
</style>
