const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        sign: null,
        isLogin: window.$cookies.get('ucon_user'),
        // isLogin:true,
        qrcode: null,
    }),

    getters: {
        Signature: (state) => state.sign,
        loggedIn(state) {
            return state.isLogin;
        },
        QrCode: (state) => state.qrcode,
    },

    actions: {
        getSignature: async ({ commit }, input) => {
            axios.post('auth/login/wallet', input).then((res) => {
                if (res.sign && res.sign.length > 0) {
                    commit('GET_SIGNATURE_SUCCESS', res);
                }
            });
        },
        LoginAddress: ({ commit }, input) => {
            axios.post('auth/login/wallet', input).then((res) => {
                if (res.tokens && res.tokens.access.token.length > 0) {
                    commit('LOGIN_SUCCESS', res);
                }
            });
        },
        Register: ({ commit }, input) => {
            axios.post('auth/register', input).then((res) => {
                commit('REGISTER_SUCCESS', res);
            });
        },
        CheckRegister: ({ commit }, input) => {
            axios.post('auth/market/register/system', input).then((res) => {
                commit('REGISTER_SYSTEM_SUCCESS', res);
            });
        },
        QrCode: ({ commit }) => {
            axios.post('auth/login/qrcode', {}).then((res) => {
                commit('SET_QRCODE', res);
            });
        },
    },
    mutations: {
        LOGOUT_SUCCESS() {
            window.$cookies.remove('ucon_user');
            window.location.replace('/login');
        },
        GET_SIGNATURE_SUCCESS: (state, data) => {
            state.sign = data.sign;
        },
        LOGIN_SUCCESS: (state, data) => {
            state.isLogin = true;
            window.$cookies.remove('ucon_user');
            const { access } = data.tokens;
            console.log(access.token);
            axios.defaults.headers.common.Authorization = `Bearer ${access.token}`;
            window.$cookies.set('ucon_user', { key: access.token }, '8H');
        },
        REGISTER_SUCCESS(state, data) {
            console.log(data);
        },
        REGISTER_SYSTEM_SUCCESS(state, data) {
            console.log(data);
        },
        SET_QRCODE(state, data) {
            state.qrcode = data;
        },
    },
};
