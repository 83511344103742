<script>
import { mapMutations, mapGetters } from 'vuex';

export default {
    props: {
        isBuy: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters({
            isNetWork: 'contract/isNetWork',
        }),
        ChainID() {
            if (this.isNetWork === 'testnet') {
                return 97;
            }
            return 56;
        },
        netWorkConfig() {
            const testNet = {
                chainId: '0x61',
                chainName: 'Smart Chain - Testnet',
                nativeCurrency: {
                    name: 'Binance Coin',
                    symbol: 'BNB',
                    decimals: 18,
                },
                rpcUrls: [
                    'https://data-seed-prebsc-2-s2.binance.org:8545/',
                    'https://data-seed-prebsc-1-s1.binance.org:8545/',
                    'https://data-seed-prebsc-5-s5.binance.org:8545/',
                    'https://data-seed-prebsc-3-s3.binance.org:8545/',
                    'https://data-seed-prebsc-4-s4.binance.org:8545/',
                ],
                blockExplorerUrls: ['https://testnet.bscscan.com'],
            };
            const mainNet = {
                chainId: '0x38',
                chainName: 'Smart Chain',
                nativeCurrency: {
                    name: 'Binance Coin',
                    symbol: 'BNB',
                    decimals: 18,
                },
                rpcUrls: [
                    'https://bsc-dataseed.binance.org/',
                    'https://bsc-dataseed1.defibit.io/',
                    'https://bsc-dataseed1.ninicoin.io/',
                ],
                blockExplorerUrls: ['https://bscscan.com'],
            };
            if (this.isNetWork === 'testnet') {
                return testNet;
            }
            return mainNet;
        },
    },
    methods: {
        ...mapMutations(['onLoad', 'outLoad']),
        async onConnectMetaMask() {
            let address = [];
            try {
                address = await window.ethereum.request({
                    method: 'eth_requestAccounts',
                });
            } catch (error) {
                this.outLoad();
                if (
                    error.message ===
                    'Already processing eth_requestAccounts. Please wait.'
                ) {
                    this.$toastr.e('Please Connect Metamask To Join', 'Oops!');
                    return false;
                }
                this.$toastr.e(error.message, 'Oops!');
                return false;
            }
            if (window.ethereum.chainId !== this.ChainID) {
                const params = this.netWorkConfig;
                window.ethereum
                    .request({
                        method: 'wallet_addEthereumChain',
                        params: [params],
                    })
                    .then(() => console.log('add network success'))
                    .catch(() => {
                        this.showNotification = true;
                        this.message = {
                            content:
                                'Please switch To The  Binance Smartchain Network!',
                            failed: true,
                            title: 'Oops...',
                        };
                        window.ethereum.request({
                            method: 'wallet_switchEthereumChain',
                            params: [{ chainId: this.ChainID }],
                        });
                    });
            }
            if (address.length < 0) {
                return false;
            }
            this.$store.commit('info/SET_ADDRESS', {
                address: address[0],
            });
            this.$store.dispatch('auth/getSignature', {
                address: address[0],
            });
            return true;
        },
        async onLogin({ address, signature }) {
            const txData = await window.ethereum.request({
                method: 'personal_sign',
                params: [address, signature],
            });
            this.$store.dispatch('auth/LoginAddress', {
                address,
                sign: txData,
            });
        },
    },
    mounted() {},
    created() {
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
            // eslint-disable-next-line default-case
            switch (mutation.type) {
                case 'auth/GET_SIGNATURE_SUCCESS':
                    this.onLogin({
                        address: state.info.address,
                        signature: state.auth.sign,
                    });
                    break;
            }
        });
    },
    beforeDestroy() {
        this.unsubscribe();
    },
};
</script>

<template>
    <button
        variant="none"
        type="button"
        class="metamaskLogin"
        @click="onConnectMetaMask"
    >
        Login
    </button>
</template>
<style lang="scss">
.metamaskLogin {
    border-radius: 100vw;
    border: 1px solid var(--text-1, #fff);
    box-shadow: 0px 2px 11.6px 1px rgba(176, 176, 176, 0.25) inset;
    width: 100%;
    text-align: center;
    color: #fff;
    font-style: normal;
    font-weight: 600;
    padding: 8px 10px;
    font-size: 16px;
    line-height: normal;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (min-width: 768px) {
        font-size: 18px;
    }

    img {
        margin-right: 5px;
        height: 25px;
        width: 25px;
    }

    &:hover {
        background: linear-gradient(50deg, #5a41bd 14.98%, #d629f2 106.07%);
        box-shadow: 0px 2px 11.6px 1px rgba(176, 176, 176, 0.25) inset;
    }
}
</style>
