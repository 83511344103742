const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        history: {
            list: [],
            current: 1,
            total: 1,
        },
        login: {
            list: [],
            current: 1,
            total: 1,
        },
        listType: [],
        totalHistory: {},
    }),
    getters: {
        History: (state) => state.history,
        Login: (state) => state.login,
        ListType: (state) => state.listType,
        TotalHistory: (state) => state.totalHistory,
    },
    actions: {
        req_getHistoryLogin: ({ commit }, input) => {
            axios
                .get('api/history/login', {
                    params: input,
                })
                .then((response) => {
                    commit('SET_LOGIN', response);
                });
        },
        req_getHistoryWalletType: ({ commit }) => {
            axios.get('/user/activity-type').then((response) => {
                commit('SET_HISTORY_TYPE', response);
            });
        },
        req_getHistoryWallet: ({ commit }, input) => {
            axios.get('/user/activity-history', {
                params:input
            }).then((response) => {
                commit('SET_HISTORY', response);
            });
        },
    },
    mutations: {
        SET_LOGIN(state, data) {
            const { results, page, totalPages } = data;
            state.login = {
                list: results,
                current: page,
                total: totalPages,
            };
        },
        SET_HISTORY_TYPE(state, data) {
            for (let i = 0; i < data.length; i += 1) {
                state.listType[i] = {
                    value: data[i],
                    text: data[i],
                };
            }
        },
        SET_HISTORY(state, data) {
            const { results, page, totalPages } =
                data;
            state.history = {
                list: results,
                current: page,
                total: totalPages,
            };
    
        },
    },
};
