<script>
import { mapGetters, mapMutations } from 'vuex';
import Paginate from '@/components/shared/Paginate.vue';
import ICountUp from 'vue-countup-v2';
import moment from 'moment';
import CountDown from '@/components/shared/CountDown.vue';
// import { filter } from 'lodash';

import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

export default {
    components: { ICountUp, Paginate, CountDown },
    data() {
        return {
            infoPreSale: {
                priceUcon: 0,
                amountBNB: 0,
                amountUcon: 0,
                amountUsdt: 0,
                minBuy: {
                    usdt: 0,
                    ucon: 0,
                },
                maxBuy: {
                    usdt: 0,
                    ucon: 0,
                },
                approve: false,
                priceBNB: 0,
                vestingPercent: 0,
            },
            delay: 2000,
            options2: {
                useEasing: true,
                useGrouping: true,
                separator: ',',
                decimal: '.',
                prefix: '',
                suffix: ' UCON',
                decimalPlaces: 0,
            },
            optionsUSDT: {
                useEasing: true,
                useGrouping: true,
                separator: ',',
                decimal: '.',
                prefix: '',
                suffix: ' USDT',
                decimalPlaces: 0,
            },
            options3: {
                useEasing: true,
                useGrouping: true,
                separator: ',',
                decimal: '.',
                prefix: '',
                suffix: '',
                decimalPlaces: 4,
            },
            preSaleInfo: {
                total: 9000000,
                price: 0.04,
                sell: 7200000,
            },
            listDefaults: [100, 150, 200, 300, 500],
            balancePreSale: {
                presaleBalance: 0,
                pHBG: 0,
            },
            claim: {
                amount: 0,
            },
            listMission: [
                {
                    _id: 'Event Summon',
                    total: 0,
                },
                {
                    _id: 'Event LuckyHero',
                    total: 0,
                },
                {
                    _id: 'Event Battle',
                    total: 0,
                },
                {
                    _id: 'Event Active Member',
                    total: 0,
                },
            ],
            tableFields: [
                {
                    key: 'amount',
                    label: 'Amount Claimed',
                    sortable: false,
                    class: 'text-center align-middle',
                },
                {
                    key: 'transaction',
                    label: 'Transaction',
                    sortable: false,
                    class: 'text-center align-middle',
                },
                {
                    key: 'createdAt',
                    label: 'Time',
                    sortable: false,
                    class: 'text-center text-capitalize align-middle',
                },

                {
                    key: 'status',
                    label: 'Status',
                    class: 'text-center align-middle',
                },
            ],
            tableFields2: [
                {
                    key: 'amountUSDT',
                    label: 'USDT',
                    sortable: false,
                    class: 'text-center align-middle',
                },
                {
                    key: 'amountUCON',
                    label: 'UCON',
                    sortable: false,
                    class: 'text-center align-middle',
                },
                {
                    key: 'stage',
                    label: 'Stage',
                    class: 'text-center align-middle',
                },
                {
                    key: 'createdAt',
                    label: 'Time',
                    class: 'text-center align-middle',
                },
                {
                    key: 'transaction',
                    label: 'Transaction',
                    class: 'text-center align-middle',
                },
                {
                    key: 'address',
                    label: 'Address',
                    sortable: false,
                    class: 'text-center text-capitalize align-middle',
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: false,
                    class: 'text-center align-middle',
                },
            ],
            luckyEmail: '',
            schedule: [
                {
                    stage: 1,
                    date: '2024-01-30',
                    end: '2024-02-01',
                    days: [
                        {
                            perDay: 210000000,
                            rate: 0.001,
                        },
                        {
                            perDay: 210000000,
                            rate: 0.0011,
                        },
                        {
                            perDay: 210000000,
                            rate: 0.0012,
                        },
                    ],
                },
                {
                    stage: 2,
                    date: '2024-02-19',
                    end: '2024-02-21',
                    days: [
                        {
                            perDay: 210000000,
                            rate: 0.0014,
                        },
                        {
                            perDay: 210000000,
                            rate: 0.0016,
                        },
                        {
                            perDay: 210000000,
                            rate: 0.0018,
                        },
                    ],
                },
                {
                    stage: 3,
                    date: '2024-02-28',
                    end: '2024-02-30',
                    days: [
                        {
                            perDay: 210000000,
                            rate: 0.002,
                        },
                        {
                            perDay: 210000000,
                            rate: 0.0022,
                        },
                        {
                            perDay: 420000000,
                            rate: 0.0024,
                        },
                    ],
                },
            ],
            now: moment(),
            listingTime: moment('2024-03-06T14:30+00:00'),
            table: 'history',
            queryFilter: {
                limit: 10,
                page: 1,
                sortBy: 'createAt:desc',
            },
        };
    },
    computed: {
        ...mapGetters({
            Info: 'preSale/Info',
            List: 'preSale/List',
            Statistical: 'preSale/Statistical',
            isLogin: 'auth/loggedIn',
            UserInfo: 'info/UserInfo',
            Claimed: 'preSale/Claimed',
            isNetWork: 'contract/isNetWork',
        }),
        currentStage() {
            let index = 0;
            while (
                this.schedule[index + 1] &&
                moment(this.schedule[index].date).add(4, 'days') < this.now
            ) {
                index += 1;
            }
            return this.schedule[index];
        },
        nextDayStage() {
            let index = 0;
            while (
                moment(this.currentStage.date).add(index, 'days') <= this.now
            ) {
                index += 1;
            }
            return `${moment(this.currentStage.date)
                .add(index, 'days')
                .format('YYYY-MM-DD')}T00:00:00-02:00`;
        },
        missionInfo() {
            const arr = [
                {
                    name: 'Active nodes status',
                    key: 'Event Summon',
                },
                {
                    name: 'Total F1 Node',
                    key: 'Event Play',
                },
                {
                    name: 'Total commission',
                    key: 'Event Battle',
                },
            ];
            return arr;
        },
    },
    methods: {
        ...mapMutations(['onLoad', 'outLoad']),
        getURL(url) {
            if (this.isNetWork === 'mainnet') {
                return `https://bscscan.com/tx/${url}`;
            }
            return `https://testnet.bscscan.com//tx/${url}`;
        },
        CopyThis(input) {
            if (window.navigator.clipboard) {
                window.navigator.clipboard
                    .writeText(input)
                    .then(() => {
                        this.$toastr.s('Address copied', 'Successfully');
                    })
                    .catch(() => {
                        this.$toastr.e('Failed to copy', 'Error');
                    });
            } else {
                this.$toastr.e('Failed to copy', 'Error');
            }
        },
        async onChangeAmount({ type = 'usdt' }) {
            if (type === 'ucon') {
                this.infoPreSale.amountUsdt =
                    this.infoPreSale.amountUcon * this.infoPreSale.priceUcon;
            } else {
                this.infoPreSale.amountUcon =
                    this.infoPreSale.amountUsdt / this.infoPreSale.priceUcon;
            }
        },
        selectedAmount(amount) {
            this.infoPreSale.amountUsdt = amount;
            this.onChangeAmount({ type: 'usdt' });
        },
        messageErrorToastr(message) {
            this.$toastr.e(
                message.replace('Returned error: execution reverted: ', ''),
                'Failed',
            );
        },

        async OnClaim() {
            const canClaim = await this.$store.dispatch(
                'preSale/req_getClaimPermission',
            );
            if (canClaim) {
                this.onLoad();
                const { vesting } = this.$store.state.contract.marketplace;
                const { web3 } = this.$store.state.contract;
                const { addr } = this.$store.state.wallet;
                // conditions amount
                const txObj = vesting.cont.methods.claimToken().encodeABI();
                let gas = 0;
                try {
                    gas = await vesting.cont.methods.claimToken().estimateGas({
                        from: addr,
                        value: '0',
                        gas: 5000000,
                    });
                } catch (error) {
                    this.outLoad();
                    this.messageErrorToastr(error.message);
                    return false;
                }
                const gasBuyPrice = await web3.provider.eth.getGasPrice();
                let reqBuy = null;
                // end request to web3
                try {
                    reqBuy = await window.ethereum.request({
                        method: 'eth_sendTransaction',
                        params: [
                            {
                                from: addr,
                                to: vesting.addr,
                                gas: web3.provider.utils.toHex(gas + 50000),
                                gasPrice:
                                    web3.provider.utils.toHex(gasBuyPrice),
                                value: '0',
                                data: web3.provider.utils.toHex(txObj),
                            },
                        ],
                    });
                    // result transaction
                    let submitted = true;
                    const check = setInterval(async () => {
                        const txBuyData =
                            await web3.provider.eth.getTransactionReceipt(
                                reqBuy,
                            );
                        if (txBuyData != null) {
                            clearInterval(check);
                            this.outLoad();
                            if (txBuyData.status) {
                                if (!submitted) {
                                    return;
                                }
                                submitted = false;
                                this.$toastr.s(
                                    'Claim UCON Success',
                                    'Successfully!',
                                );
                                this.sendTransaction({
                                    type: 'claim',
                                    hash: txBuyData.transactionHash,
                                });
                                await this.setUpInfoBuy();
                                this.onGetListClaimed(1);
                                this.$bvModal.hide('claimUcon');
                            } else {
                                this.$toastr.e(
                                    'Transaction failed',
                                    'Please try again',
                                );
                            }
                        }
                    }, 1000);
                } catch (error) {
                    this.outLoad();
                    this.messageErrorToastr(error.message);
                    return false;
                }
                return true;
            }
            this.$toastr.e('User does not meet claim requirements', 'Error');
            return false;
        },
        async onClaimAirdrop() {
            this.$bvModal.show('claimUcon');
        },
        sendTransaction({ type, hash }) {
            this.$store.commit('wallet/updateWallet');
            if (type === 'presale') {
                this.$store.dispatch('preSale/req_postBuyToken', {
                    type: 'presale',
                    transaction: hash,
                });
            }
            if (type === 'claim') {
                this.$store.dispatch('preSale/req_postClaimToken', {
                    transaction: hash,
                });
            }
        },
        resetClaim() {
            this.claim.amount = 0;
        },
        moment,
        comingSoon() {
            this.$toastr.w('Coming soon', 'Unavailable');
        },

        // presale contract config
        async setUpInfoBuy() {
            const preSaleMethods = await this.$store.state.contract.marketplace
                .preSale.cont.methods;
            const vestingMethods = await this.$store.state.contract.marketplace
                .vesting.cont.methods;
            const usdtDec = this.$store.state.contract.bep_20.usdt.dec;

            if (preSaleMethods === undefined || vestingMethods === undefined) {
                await this.sleep(2_000);
                await this.setUpInfoBuy();
                return;
            }
            const price = +(
                (await preSaleMethods.price().call()) / usdtDec
            ).toFixed(6);

            const vestingPercent = +(await vestingMethods
                .percentClaim()
                .call());

            const min = +(
                (await preSaleMethods.min_buy().call()) / usdtDec
            ).toFixed(0);

            const max = +(
                (await preSaleMethods.max_buy().call()) / usdtDec
            ).toFixed(0);
            this.infoPreSale.vestingPercent = vestingPercent;

            this.infoPreSale.priceUcon = price;
            this.infoPreSale.minBuy = {
                usdt: min,
                ucon: min / price,
            };
            this.infoPreSale.maxBuy = {
                usdt: max,
                ucon: max / price,
            };
            this.infoPreSale.amountUsdt = parseInt(min, 10);
            this.onChangeAmount({
                type: 'usdt',
            });
            await this.checkApproval();
        },
        async checkApproval() {
            const { usdt } = this.$store.state.contract.bep_20;
            const { preSale } = this.$store.state.contract.marketplace;
            const { addr } = this.$store.state.wallet;
            const { maxBuy } = this.infoPreSale;
            const amountApprove =
                +(await usdt.cont.methods
                    .allowance(addr, preSale.addr)
                    .call()) / usdt.dec;

            this.infoPreSale.approve = amountApprove >= maxBuy.usdt;
        },
        async Approve() {
            const { usdt } = this.$store.state.contract.bep_20;
            const { web3 } = this.$store.state.contract;
            const { preSale } = this.$store.state.contract.marketplace;
            const { addr } = this.$store.state.wallet;
            const { maxBuy } = this.infoPreSale;

            const txObj = usdt.cont.methods
                .approve(
                    preSale.addr,
                    (maxBuy.usdt * 10 ** 18).toLocaleString('fullwide', {
                        useGrouping: false,
                    }),
                )
                .encodeABI();
            let gas = 0;
            try {
                // Calculate gas
                gas = await usdt.cont.methods
                    .approve(
                        preSale.addr,
                        (maxBuy.usdt * 10 ** 18).toLocaleString('fullwide', {
                            useGrouping: false,
                        }),
                    )
                    .estimateGas({
                        from: addr,
                        value: '0',
                        gas: 5000000,
                    });
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                return;
            }

            const gasPrice = await web3.provider.eth.getGasPrice();

            let res;
            try {
                res = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: usdt.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                // Kết quả transaction
                const check = setInterval(async () => {
                    const txData =
                        await web3.provider.eth.getTransactionReceipt(res);
                    if (txData != null) {
                        clearInterval(check);
                        if (txData.status) {
                            this.$toastr.s('Approve success !', 'Successfully');
                            await this.checkApproval();
                            this.BuyUcon();
                        } else {
                            this.messageErrorToastr(
                                'Transaction failed ! Please try again.',
                            );
                        }
                    }
                }, 1_000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
            }
        },
        async conditionsAmount() {
            const { amountUcon, amountUsdt, maxBuy, minBuy } = this.infoPreSale;

            const { TotalBought } = this.Statistical;
            if (amountUcon <= 0 || amountUsdt <= 0) {
                this.$toastr.w(
                    `Buy amount must bigger than: ${minBuy.usdt} USDT (${minBuy.ucon} UCON)`,
                    'Buy Failed',
                );
                this.outLoad();
                return false;
            }
            if (TotalBought >= maxBuy.ucon) {
                this.$toastr.e("You can't buy anymore", 'Failed!');
                return false;
            }

            if (amountUcon < minBuy.ucon || amountUsdt < minBuy.usdt) {
                this.$toastr.w(
                    `Buy amount must bigger than: ${minBuy.usdt} USDT (${minBuy.ucon} UCON)`,
                    'Buy Failed',
                );
                this.outLoad();
                return false;
            }
            if (amountUcon > maxBuy.ucon) {
                this.$toastr.w(
                    `Buy amount must lower than : ${maxBuy.usdt} USDT (${maxBuy.ucon} UCON)`,
                    'Buy Failed',
                );
                this.outLoad();
                return false;
            }
            if (amountUcon) {
                if (TotalBought + amountUcon > maxBuy.ucon) {
                    const overbalance =
                        maxBuy.ucon - parseInt(TotalBought + amountUcon, 10);
                    this.infoPreSale.amountHBG = overbalance;
                    this.$toastr.w(
                        `You can buy:${overbalance} HBG`,
                        'Buy Failed',
                    );

                    return overbalance;
                }
            }
            return parseInt(minBuy.ucon + 1, 10);
        },
        async BuyUcon() {
            this.onLoad();
            const { amountUsdt, approve } = this.infoPreSale;
            const { preSale } = this.$store.state.contract.marketplace;
            const { web3 } = this.$store.state.contract;
            const { usdt } = this.$store.state.contract.bep_20;
            const { addr } = this.$store.state.wallet;

            // check conditions token
            if (await this.conditionsAmount()) {
                this.infoPreSale.amountUcon = this.conditionsAmount();
                await this.onChangeAmount({
                    type: 'ucon',
                });
            } else {
                this.outLoad();
                return false;
            }

            // check user approve
            await this.checkApproval();
            if (!approve) {
                await this.Approve();
                return false;
            }
            // this.onLoad();
            // await this.sleep(5_000);
            // this.outLoad();
            // this.outLoad();
            // this.$toastr.e('Transaction failed', 'Ucon is sold out!');
            const usdtHex = (amountUsdt * usdt.dec - 1)
                .toFixed(0)
                .toLocaleString('fullwide', {
                    useGrouping: false,
                });

            const txObj = preSale.cont.methods.buyToken(usdtHex).encodeABI();
            let gas = 0;
            try {
                gas = await preSale.cont.methods.buyToken(usdtHex).estimateGas({
                    from: addr,
                    value: '0',
                    gas: 5000000,
                });
            } catch (error) {
                this.outLoad();
                console.log(error);
                this.messageErrorToastr(error.message);
                this.setUpInfoBuy();
                return false;
            }
            const gasBuyPrice = await web3.provider.eth.getGasPrice();
            let reqBuy = null;
            // end request to web3

            // eslint-disable-next-line no-unreachable
            try {
                reqBuy = await window.ethereum.request({
                    method: 'eth_sendTransaction',
                    params: [
                        {
                            from: addr,
                            to: preSale.addr,
                            gas: web3.provider.utils.toHex(gas + 50000),
                            gasPrice: web3.provider.utils.toHex(gasBuyPrice),
                            value: '0',
                            data: web3.provider.utils.toHex(txObj),
                        },
                    ],
                });
                // result transaction
                let submitted = true;
                const check = setInterval(async () => {
                    const txBuyData =
                        await web3.provider.eth.getTransactionReceipt(reqBuy);
                    if (txBuyData != null) {
                        clearInterval(check);
                        this.outLoad();
                        if (txBuyData.status) {
                            if (!submitted) {
                                return;
                            }
                            submitted = false;
                            this.sendTransaction({
                                type: 'presale',
                                hash: txBuyData.transactionHash,
                            });
                            await this.setUpInfoBuy();
                        } else {
                            this.$toastr.e(
                                'Transaction failed',
                                'Please try again',
                            );
                            this.setUpInfoBuy();
                        }
                    }
                }, 1000);
            } catch (error) {
                this.outLoad();
                this.messageErrorToastr(error.message);
                this.setUpInfoBuy();
                return false;
            }
            return true;
        },

        // vesting
        onGetListClaimed(page) {
            this.queryFilter.page = page;
            this.$store.dispatch(
                'preSale/req_getListClaimed',
                this.queryFilter,
            );
        },
    },
    async mounted() {
        this.$store.dispatch('preSale/req_getInfoPreSales');
        const userRaw = this.$store.getters['auth/loggedIn'];
        if (userRaw !== undefined && userRaw !== null) {
            this.$store.dispatch('preSale/req_getBuyHistory');
            this.onGetListClaimed(1);
        }
        if (this.$route.query.s && this.$route.query.m) {
            if (this.$route.query.s === 1) {
                this.$toastr.s(this.$route.query.m, 'Successfully !!!');
            }
            if (this.$route.query.s !== 1) {
                this.$toastr.e(this.$route.query.m, 'Failed !!!');
            }
        }
        this.$store.watch(
            (state) => state.contract.marketplace.preSale.cont,
            () => {
                this.setUpInfoBuy();
            },
        );
        const preSale = await this.$store.state.contract.marketplace.preSale
            .cont;
        if (preSale) {
            await this.setUpInfoBuy();
        }
    },
};
</script>

<template>
    <div class="pre-sale">
        <div class="section section-1">
            <div class="carousel-item-home">
                <b-container class="cs-tablet">
                    <b-row
                        data-aos="fade-up"
                        data-aos-delay="50"
                        data-aos-duration="1000"
                        data-aos-easing="ease-in-out"
                        data-aos-mirror="true"
                        class="align-items-center"
                    >
                        <b-col cols="12" lg="7">
                            <div class="section-title">
                                Listing Pancake Schedule
                            </div>
                            <div
                                class="d-flex flex-lg-column justify-content-around align-items-center align-items-lg-start flex-wrap"
                            >
                                <div class="box-count">
                                    <div class="line"></div>
                                    <CountDown
                                        :deadline="listingTime"
                                    ></CountDown>
                                    <div class="line"></div>
                                </div>
                                <div>
                                    <div class="section-title-sg">
                                        Listing:
                                        <span class="timeprogress">
                                            {{
                                                moment(listingTime).format(
                                                    'DD/MM/YYYY',
                                                )
                                            }}
                                            - 14:30
                                        </span>
                                        <!-- <button
                                            class="more-info"
                                            @click="$bvModal.show('schedule')"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10m0-2a8 8 0 1 0 0-16a8 8 0 0 0 0 16m1-9.5V15h1v2h-4v-2h1v-2.5h-1v-2zm.5-2.5a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0"
                                                />
                                            </svg>
                                        </button> -->
                                    </div>
                                    <!-- <div class="days">
                                        <div
                                            v-for="(
                                                item, index
                                            ) in currentStage.days"
                                            :key="index"
                                            :class="{
                                                itsover:
                                                    moment(
                                                        currentStage.date,
                                                    ).add(index + 1, 'day') <
                                                    now,
                                            }"
                                            class="section-title-sg"
                                        >
                                            <div class="date">
                                                <span>
                                                    {{
                                                        moment(
                                                            currentStage.date,
                                                        )
                                                            .add(index, 'day')
                                                            .format(
                                                                'DD/MM/YYYY',
                                                            )
                                                    }}
                                                </span>
                                                <span
                                                    v-if="
                                                        moment(
                                                            currentStage.date,
                                                        ).add(
                                                            index + 1,
                                                            'day',
                                                        ) < now
                                                    "
                                                    class="owari"
                                                >
                                                    ENDED
                                                </span>
                                            </div>
                                            <div class="token">
                                                -
                                                <div>
                                                    <span>{{
                                                        TruncateToDecimals2(
                                                            item.perDay,
                                                            '',
                                                            0,
                                                        )
                                                    }}</span>
                                                    a day at
                                                </div>
                                                <div>
                                                    <span>
                                                        {{ item.rate }}
                                                    </span>
                                                    USDT
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </b-col>
                        <b-col cols="12" lg="5" class="cs-mk">
                            <div class="box-image">
                                <img
                                    src="@/assets/images/mockup/outline.png"
                                    class="outside-img"
                                />
                                <img
                                    src="@/assets/images/mockup/outline.png"
                                    class="outside-img2"
                                />
                                <img
                                    src="@/assets/images/mockup/inside.png"
                                    class="inside-img"
                                />
                            </div>
                        </b-col>
                    </b-row>
                </b-container>
            </div>
        </div>
        <div class="section section-2">
            <b-container>
                <div class="box-preSale">
                    <div class="header-preSale">
                        <div class="section-title">BUY TOKEN</div>
                    </div>
                    <div class="body-preSale">
                        <b-form @submit.prevent="BuyUcon">
                            <div class="form-group">
                                <div class="label">
                                    <span class="label-main">
                                        <img
                                            src="~@/assets/images/logo/usdt.svg"
                                            alt=""
                                            width="30px"
                                        />
                                        USDT
                                    </span>
                                </div>
                                <input
                                    @change="onChangeAmount"
                                    v-model.number="infoPreSale.amountUsdt"
                                    type="number"
                                />
                                <div class="option">
                                    <b-button
                                        v-for="usdt in listDefaults"
                                        :key="`value-${usdt}`"
                                        @click="selectedAmount(usdt)"
                                    >
                                        {{ usdt }} USDT
                                    </b-button>
                                </div>
                            </div>

                            <b-button class="btn-change">
                                <img
                                    src="~@/assets/images/icons/arrows.png"
                                    alt=""
                                />
                            </b-button>

                            <div class="form-group">
                                <div class="label">
                                    <span class="label-main">
                                        <img
                                            src="~@/assets/images/logo/ucon.png"
                                            alt=""
                                            width="30px"
                                        />
                                        UCON
                                    </span>

                                    <span class="price">
                                        1 UCON =
                                        {{ infoPreSale.priceUcon }} USDT
                                    </span>
                                </div>
                                <input
                                    @change="onChangeAmount({ type: 'ucon' })"
                                    v-model.number="infoPreSale.amountUcon"
                                    type="number"
                                />
                            </div>
                            <div class="form-group">
                                <div class="buy-HBG option">
                                    <router-link
                                        :to="{
                                            name: 'Login',
                                        }"
                                        v-if="!isLogin"
                                    >
                                        LogIn
                                    </router-link>

                                    <b-button
                                        type="submit"
                                        @click.prevent="BuyUcon"
                                        v-else
                                    >
                                        Buy Ucon
                                    </b-button>
                                </div>
                                <!-- <div class="address">
                                    Presale wallet:
                                    0x2e2a0cB8CED35189516e7900CeD2BED5a345954b
                                    <button
                                        type="button"
                                        @click="
                                            CopyThis(
                                                '0x2e2a0cB8CED35189516e7900CeD2BED5a345954b',
                                            )
                                        "
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="1.5em"
                                            height="1.5em"
                                            viewBox="0 0 24 24"
                                        >
                                            <g
                                                fill="none"
                                                stroke="white"
                                                stroke-width="1.5"
                                            >
                                                <path
                                                    d="M6 11c0-2.828 0-4.243.879-5.121C7.757 5 9.172 5 12 5h3c2.828 0 4.243 0 5.121.879C21 6.757 21 8.172 21 11v5c0 2.828 0 4.243-.879 5.121C19.243 22 17.828 22 15 22h-3c-2.828 0-4.243 0-5.121-.879C6 20.243 6 18.828 6 16z"
                                                />
                                                <path
                                                    d="M6 19a3 3 0 0 1-3-3v-6c0-3.771 0-5.657 1.172-6.828C5.343 2 7.229 2 11 2h4a3 3 0 0 1 3 3"
                                                />
                                            </g>
                                        </svg>
                                    </button>
                                    <div class="note">
                                        Note: Please check the wallet in the
                                        system before each transfer to avoid
                                        sending to the wrong address, causing
                                        potential loss
                                        <div class="pt-3">
                                            Note: The wallet has just been
                                            updated, the 0.0014$ round has sold
                                            out. The new receiving wallet for
                                            the 0.0016$.
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </b-form>
                        <button
                            class="stage-info"
                            @click="$bvModal.show('schedule')"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    fill="currentColor"
                                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10m0-2a8 8 0 1 0 0-16a8 8 0 0 0 0 16m1-9.5V15h1v2h-4v-2h1v-2.5h-1v-2zm.5-2.5a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0"
                                />
                            </svg>
                            Details
                        </button>
                    </div>
                </div>
            </b-container>
            <b-container>
                <b-col cols="12" lg="8" xl="10" class="mx-auto">
                    <div class="pre-sale__heading mt-4">
                        <div class="text">PRE-SALES SCHEDULE</div>
                    </div>
                    <b-row>
                        <b-col cols="12">
                            <b-row class="overflow-hidden">
                                <b-col cols="12" md="6" class="my-3 mx-auto">
                                    <div class="box-token main-box">
                                        <div class="box-token-header border-b">
                                            <div
                                                class="box-token-header-image"
                                            ></div>
                                            <div class="box-token-header-title">
                                                Total Bought
                                            </div>
                                        </div>
                                        <div class="box-token-body">
                                            <div class="box-token-body-image">
                                                <img
                                                    src="~@/assets/images/logo/ucon.png"
                                                    alt=""
                                                />
                                            </div>
                                            <div class="box-token-body-amount">
                                                <ICountUp
                                                    v-if="
                                                        !isNaN(
                                                            Statistical.totalBought,
                                                        )
                                                    "
                                                    :delay="delay"
                                                    :endVal="
                                                        Statistical.totalBought
                                                    "
                                                    :options="options3"
                                                />
                                                <ICountUp
                                                    v-else
                                                    :delay="delay"
                                                    :endVal="0"
                                                    :options="options3"
                                                />
                                            </div>
                                            <div class="box-token-body-symbol">
                                                UCON
                                            </div>
                                        </div>
                                        <div class="box-token-footer">
                                            <b-button
                                                v-if="isLogin"
                                                class="box-token-footer-button"
                                                @click="onClaimAirdrop"
                                            >
                                                <div
                                                    class="box-token-footer-button-text"
                                                >
                                                    Claim UCON
                                                </div>
                                            </b-button>
                                        </div>
                                    </div>
                                </b-col>
                                <b-col cols="12" md="6" class="my-3 mx-auto">
                                    <div class="box-token main-box">
                                        <div class="box-token-header border-b">
                                            <div
                                                class="box-token-header-image"
                                            ></div>
                                            <div class="box-token-header-title">
                                                Total Claimed
                                            </div>
                                        </div>
                                        <div class="box-token-body">
                                            <div class="box-token-body-image">
                                                <img
                                                    src="~@/assets/images/logo/ucon.png"
                                                    alt=""
                                                />
                                            </div>
                                            <div class="box-token-body-amount">
                                                <ICountUp
                                                    v-if="
                                                        !isNaN(
                                                            Statistical.totalLocking,
                                                        )
                                                    "
                                                    :delay="delay"
                                                    :endVal="
                                                        Statistical.totalLocking
                                                    "
                                                    :options="options3"
                                                />
                                                <ICountUp
                                                    v-else
                                                    :delay="delay"
                                                    :endVal="0"
                                                    :options="options3"
                                                />
                                            </div>
                                            <div class="box-token-body-symbol">
                                                UCON
                                            </div>
                                        </div>
                                        <div class="box-token-footer"></div>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="12" class="mx-auto">
                            <div class="box-mission box-mission-2 mx-auto my-3">
                                <div class="text">
                                    <div class="header-box text-center">
                                        <div class="text-title">
                                            Presale Data
                                        </div>
                                    </div>
                                    <div class="pending">
                                        <div class="pending-content">
                                            <div class="pending-content-left">
                                                Active nodes status
                                            </div>
                                            <div class="pending-content-right">
                                                0
                                            </div>
                                        </div>
                                        <div class="pending-content">
                                            <div class="pending-content-left">
                                                Total F1 Node
                                            </div>
                                            <div class="pending-content-right">
                                                0
                                            </div>
                                        </div>
                                        <div class="pending-content">
                                            <div class="pending-content-left">
                                                Total Downline sales
                                            </div>
                                            <div class="pending-content-right">
                                                {{ Statistical.downlineUCON }}
                                                (UCON)
                                            </div>
                                            <div class="pending-content-right">
                                                {{ Statistical.downLineUSDT }}
                                                (USDT)
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
            </b-container>
            <b-container class="box-unlock">
                <b-col cols="12" lg="8" xl="10" class="mx-auto">
                    <div class="unlock__history mt-5">
                        <div class="unlock__history__box__table">
                            <div class="pre-sale__heading mt-4">
                                <div class="text">UNLOCK SCHEDULE</div>
                            </div>
                            <div class="unlock-tabs">
                                <button
                                    :class="{ active: table === 'claim' }"
                                    @click="table = 'claim'"
                                >
                                    Claim
                                </button>
                                <button
                                    :class="{ active: table === 'history' }"
                                    @click="table = 'history'"
                                >
                                    History
                                </button>
                            </div>
                            <div
                                v-if="table === 'claim'"
                                class="table-responsive table-vac"
                            >
                                <b-table
                                    striped
                                    hover
                                    :items="Claimed.list"
                                    :fields="tableFields"
                                    show-empty
                                    thead-class="table-vac-header"
                                >
                                    <template #empty>
                                        <h4 class="text-center my-3">
                                            No Data
                                        </h4>
                                    </template>
                                    <template #cell(createdAt)="data">
                                        {{ getDateTime3(data.value) }}
                                    </template>
                                    <template #cell(transaction)="data">
                                        <a
                                            :href="getURL(data.value)"
                                            target="_blank"
                                        >
                                            {{
                                                `${data.value.slice(
                                                    0,
                                                    5,
                                                )}...${data.value.slice(
                                                    data.value.length - 5,
                                                    data.value.length,
                                                )}`
                                            }}
                                        </a>
                                    </template>
                                    <template #cell(amount)="data">
                                        <ICountUp
                                            :endVal="data.value || 0"
                                            :options="options2"
                                        />
                                    </template>
                                    <template #cell(status)="data">
                                        <span
                                            :class="
                                                data.value == 1
                                                    ? 'text-success'
                                                    : data.value == 0
                                                    ? 'text-warning'
                                                    : 'text-cancel'
                                            "
                                        >
                                            {{
                                                data.value == 1
                                                    ? 'Confirm'
                                                    : data.value == 0
                                                    ? 'Pending'
                                                    : 'Cancel'
                                            }}
                                        </span>
                                    </template>
                                </b-table>
                                <Paginate
                                    :total-pages="Claimed.total"
                                    @current="onGetListClaimed"
                                ></Paginate>
                            </div>
                            <div
                                v-if="table === 'history'"
                                class="table-responsive table-vac"
                            >
                                <b-table
                                    striped
                                    hover
                                    :items="List"
                                    :fields="tableFields2"
                                    show-empty
                                    thead-class="table-vac-header"
                                >
                                    <template #empty>
                                        <h4 class="text-center my-3">
                                            No Data
                                        </h4>
                                    </template>
                                    <template #cell(amountUSDT)="data">
                                        <ICountUp
                                            :endVal="data.value || 0"
                                            :options="optionsUSDT"
                                        />
                                    </template>
                                    <template #cell(amountUCON)="data">
                                        <ICountUp
                                            :endVal="data.value || 0"
                                            :options="options2"
                                        />
                                    </template>
                                    <template #cell(createdAt)="data">
                                        {{
                                            moment(data.value).format(
                                                'DD/MM/YYYY',
                                            )
                                        }}
                                    </template>
                                    <template #cell(transaction)="data">
                                        <a
                                            :href="getURL(data.value)"
                                            target="_blank"
                                        >
                                            {{
                                                `${data.value.slice(
                                                    0,
                                                    5,
                                                )}...${data.value.slice(
                                                    data.value.length - 5,
                                                    data.value.length,
                                                )}`
                                            }}
                                        </a>
                                    </template>
                                    <template #cell(address)="data">
                                        <span
                                            style="cursor: pointer"
                                            @click="CopyThis(data.value)"
                                        >
                                            {{
                                                `${data.value.slice(
                                                    0,
                                                    5,
                                                )}...${data.value.slice(
                                                    data.value.length - 5,
                                                    data.value.length,
                                                )}`
                                            }}
                                        </span>
                                    </template>
                                    <template #cell(status)="data">
                                        <span
                                            :class="
                                                data.value == 1
                                                    ? 'text-success'
                                                    : data.value == 0
                                                    ? 'text-warning'
                                                    : 'text-cancel'
                                            "
                                        >
                                            {{
                                                data.value == 1
                                                    ? 'Confirm'
                                                    : data.value == 0
                                                    ? 'Pending'
                                                    : 'Cancel'
                                            }}
                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-container>
        </div>
        <b-modal
            id="claimUcon"
            title="Claim UCON Token"
            hide-footer
            @hidden="resetClaim"
        >
            <b-form @submit.prevent="OnClaim">
                <b-form-group>
                    <label for="inputAmount"> Your Bought Token </label>
                    <b-form-input
                        type="text"
                        :value="`${TruncateToDecimals2(
                            Statistical.totalBought,
                            '',
                            4,
                        )} UCON`"
                        readonly
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <label for="inputAmount"> Your Remaining Token </label>
                    <b-form-input
                        type="text"
                        :value="`${TruncateToDecimals2(
                            Statistical.totalRemaining,
                            '',
                            4,
                        )} UCON`"
                        readonly
                    ></b-form-input>
                </b-form-group>
                <b-form-group>
                    <label> Claimable Token </label>
                    <b-form-input
                        type="text"
                        v-if="
                            infoPreSale.vestingPercent &&
                            Statistical.totalBought
                        "
                        :value="`${TruncateToDecimals2(
                            (Statistical.totalBought *
                                infoPreSale.vestingPercent) /
                                100,
                            '',
                            4,
                        )} UCON`"
                        readonly
                    ></b-form-input>
                </b-form-group>
                <div class="note">
                    <div class="title">NOTED:</div>
                    <div class="content">
                        <p
                            class="text-left text-warning mb-1 pl-3 font-weight-bold"
                            style="letter-spacing: 1px"
                        >
                            - Vesting rate is
                            {{ infoPreSale.vestingPercent }}%
                        </p>
                        <p
                            class="text-left text-warning mb-1 pl-3 font-weight-bold"
                            style="letter-spacing: 1px"
                        >
                            - If you have not received UCON to your wallet,
                            please wait a minutes for the bscscan network to
                            confirm the transaction to log into the system
                            <span> (maximum time 48h will received) </span>
                        </p>
                        <p class="text-left text-warning pl-3 font-weight-bold">
                            - If you have a question or more than 48 hours have
                            passed please contact with support team
                            <router-link :to="{ name: 'Support' }">
                                <span> In Here </span>
                            </router-link>
                        </p>
                    </div>
                </div>
                <b-button
                    class="button-confirm"
                    type="submit"
                    :disabled="Statistical.totalBought < claim.amount"
                >
                    Confirm
                </b-button>
            </b-form>
        </b-modal>
        <b-modal
            id="schedule"
            title="Listing Pancake Schedule"
            size="xl"
            hide-footer
        >
            <div class="entire-schedule">
                <div v-for="(item, index) in schedule" :key="index" class="">
                    <div class="headline">
                        Stage: {{ item.stage }}
                        -
                        {{ moment(item.date).format('DD/MM/YYYY') }}
                    </div>

                    <div class="duration">
                        Duration:
                        {{ moment(item.date).format('DD/MM/YYYY') }}
                        -
                        {{
                            moment(item.date)
                                .add(2, 'days')
                                .format('DD/MM/YYYY')
                        }}
                    </div>
                    <div class="days">
                        <div
                            v-for="(day, idx) in item.days"
                            :key="idx"
                            class="day"
                        >
                            <div class="date">
                                {{
                                    moment(item.date)
                                        .add(idx, 'days')
                                        .format('DD/MM/YYYY')
                                }}
                            </div>
                            - Total of
                            <span>{{
                                TruncateToDecimals2(day.perDay, '', 0)
                            }}</span>
                            Tokens
                            <br class="d-none d-lg-block" />
                            Rate: 1 UCON/ <span>{{ day.rate }}</span> USDT
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
        <b-modal id="stage-details" centered hide-header hide-footer size="lg">
            <div class="stage">
                <button class="close" @click="$bvModal.hide('stage-details')">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="currentColor"
                            d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                        />
                    </svg>
                </button>
                <div class="head">Stage 1</div>
                <div class="content">
                    <div>Presale data:</div>
                    <div>Nice Caulk</div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<style lang="scss">
.box-image {
    position: relative;
    width: 475px;
    aspect-ratio: 1;
    @media (max-width: 1200px) {
        width: 400px;
        aspect-ratio: 1;
    }

    img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        &.outside-img {
            z-index: 1;
            animation: rote 5s linear infinite;
        }

        &.outside-img2 {
            z-index: 1;
            animation: rote2 5s linear infinite;
        }

        &.inside-img {
            z-index: 2;

            animation: lightI 10s linear infinite;
        }
    }
}

@keyframes lightI {
    0% {
        filter: drop-shadow(0px 0px 5px rgba(182, 37, 249, 0.5));
        transform: scale(0.8);
    }
    50% {
        filter: drop-shadow(0px 0px 50px rgba(182, 37, 249, 1));
        transform: scale(0.7);
    }
    100% {
        filter: drop-shadow(0px 0px 5px rgba(182, 37, 249, 0.5));
        transform: scale(0.8);
    }
}

@keyframes rote {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(1turn);
    }
}

@keyframes rote2 {
    0% {
        transform: rotate(1turn);
    }
    100% {
        transform: rotate(0deg);
    }
}

.close-round {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'HHBI';
    font-size: 2.3em;
    color: #fff;
    text-shadow: 2px 0 0 rgba(182, 37, 249, 0.8),
        2px 0 0 rgba(182, 37, 249, 0.8), 2px 2px 0 rgba(182, 37, 249, 0.8),
        2px -2px 0 rgba(182, 37, 249, 0.8);
    letter-spacing: 2px;
    line-height: 100%;
    text-align: center;
}

.pre-sale {
    &__heading {
        display: inline-flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
        margin: 0 0 15px;

        &::before {
            content: '';
            width: 40px;
            flex: 0 0 40px;
            height: 14px;
            background: #b625f9;
            margin-right: 15px;
            margin-top: 7px;
        }

        .text {
            flex: 1 1 auto;
            margin-right: 10px;
            font-family: 'HHBI';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 28px;
            color: #f0f0f0;

            &.borderStyle {
                padding-bottom: 25px;
                border-bottom: 1px solid #fff;
            }
        }
    }

    margin-top: -20px;

    .section {
        padding: 10px 0;
        overflow: hidden;
        position: relative;

        .section-title {
            font-family: 'HHBI';
            font-size: 0.67em;
            color: #fff;
            text-shadow: 2px 0 0 rgba(182, 37, 249, 0.8),
                2px 0 0 rgba(182, 37, 249, 0.8),
                2px 2px 0 rgba(182, 37, 249, 0.8),
                2px -2px 0 rgba(182, 37, 249, 0.8);
            letter-spacing: 2px;
            line-height: 100%;
        }

        .section-title-sg {
            color: #da8bff;
            text-shadow: 0px 0 5px rgba(182, 37, 249, 0.7);
            font-size: 1.2em;
            font-family: 'HHBI';
            margin-top: 10px;

            span {
                color: #fff;
                word-break: break-all;
            }
        }

        .section-title-sg2 {
            font-size: 1.4em;
            color: #ff1900;
            text-shadow: 0px 0 5px rgba(252, 0, 0, 0.7);
            font-family: 'HHBI';
            margin-top: 10px;

            span {
                color: #fff;
                word-break: break-word;
            }
        }

        .slick-arrow {
            z-index: 30;
            width: max-content;
            height: max-content;

            &.slick-next {
                right: 1%;

                &:before {
                    content: '';
                    background: url('~@/assets/images/icons/next.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    height: 46px;
                    width: 32px;
                    display: block;
                }
            }

            &.slick-prev {
                left: 1%;

                &:before {
                    content: '';
                    background: url('~@/assets/images/icons/prev.png');
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    height: 46px;
                    width: 32px;
                    display: block;
                }
            }

            @media (min-width: 1366px) {
                &.slick-next {
                    right: -5%;
                }
                &.slick-prev {
                    left: -5%;
                }
            }
            @media (max-width: 425px) {
                &.slick-next,
                &.slick-prev {
                    &:before {
                        height: 36px;
                        width: 25px;
                    }
                }
                &.slick-next {
                    right: 1%;
                }
                &.slick-prev {
                    left: 1%;
                }
            }
        }

        @media (max-width: 767px) {
            .section-title {
                text-shadow: 2px 0 0 rgba(182, 37, 249, 0.7),
                    2px 0 0 rgba(182, 37, 249, 1),
                    2px 1px 0 rgba(182, 37, 249, 1),
                    1px -1px 0 rgba(182, 37, 249, 1);
            }
        }

        .container {
            position: relative;
            z-index: 10;
        }
    }

    .section-1 {
        // background: url('~@/assets/images/airdrop/bg-sec1.png');
        // background-repeat: no-repeat;
        // background-size: cover;
        // background-position: center center;
        background-image: radial-gradient(
                white,
                rgba(255, 255, 255, 0.2) 2px,
                transparent 40px
            ),
            radial-gradient(
                white,
                rgba(255, 255, 255, 0.15) 1px,
                transparent 30px
            ),
            radial-gradient(
                white,
                rgba(255, 255, 255, 0.1) 2px,
                transparent 40px
            ),
            radial-gradient(
                rgba(255, 255, 255, 0.4),
                rgba(255, 255, 255, 0.1) 2px,
                transparent 30px
            );
        background-size: 550px 550px, 350px 350px, 250px 250px, 150px 150px;
        background-position: 0 0, 40px 60px, 130px 270px, 70px 100px;

        &:before {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            content: '';
            z-index: 0;
            width: 100%;
            height: 1px;
            box-shadow: 0 0vw 4vw 6vw #001326;
            background: #001326;
        }

        .container-fluid {
            position: relative;
            z-index: 10;
        }

        .section-title {
            font-size: 3.5em;
            letter-spacing: 2px;
            text-shadow: 2px 0 0 rgba(182, 37, 249, 0.5),
                2px 0 0 rgba(182, 37, 249, 0.5),
                2px 2px 0 rgba(182, 37, 249, 0.5),
                2px -2px 0 rgba(182, 37, 249, 0.5),
                2px 2px rgba(182, 37, 249, 0.5),
                2px -2px 2px rgba(182, 37, 249, 0.5),
                2px -2px 2px rgba(182, 37, 249, 0.5),
                2px 2px 2px rgba(182, 37, 249, 0.5);
            text-transform: uppercase;
        }

        .section-title-sg {
            font-size: 1.4em;
        }

        .line {
            width: 80%;
            height: 2px;
            background-color: rgba(182, 37, 249, 1);
            margin: 30px 0;
            box-shadow: 1px 1px 3px rgba(182, 37, 249, 0.5);
        }

        .img-mk-home {
            width: 100%;
            height: auto;
            transform: scale(1);
            margin-top: -100px;
            margin-left: -80px;
            animation: image-fly02 3s 0.5s ease-in-out alternate infinite;
        }

        .carousel-item-home {
            min-height: calc(100vh - 90px);
            display: flex !important;
            justify-content: center;
            align-items: center;
            overflow: hidden;
        }

        .count-down {
            max-width: 500px;
            margin: 20px 0;
            position: relative;
            z-index: 10;

            .text-bottom,
            .text-top {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .right,
                .left {
                    flex: 1 1 auto;
                    display: flex;
                }

                .right {
                    justify-content: flex-end;
                }

                .left {
                    justify-content: flex-start;
                }
            }

            .progress-body {
                height: 25px;
                max-width: 500px;
                position: relative;
                margin-top: 10px;

                .bg-progress {
                    border: 1px solid #b625f9;
                    background: linear-gradient(
                        180deg,
                        #00172a 0%,
                        #00000b 100%
                    );
                    border-radius: 15px;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: auto;
                    bottom: 0;
                    z-index: 3;

                    .progress-active {
                        border: 1px solid #b625f9;
                        background: linear-gradient(
                            90deg,
                            #00ffa9 0%,
                            #00b1ff 100%
                        );
                        border-radius: 15px;
                        width: calc(40% - 4px);
                        height: calc(100% - 4px);
                        position: absolute;
                        top: 0;
                        left: 2px;
                        right: auto;
                        bottom: 0;
                        margin: auto 0;
                        z-index: 4;
                    }
                }

                .percent-text {
                    color: #00000b;
                    border-radius: 15px;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0px;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    z-index: 5;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: 600;

                    &.low {
                        color: #fff;
                    }
                }
            }
        }

        .cs-mk {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .days {
            > div {
                width: fit-content;
                padding: 0px 5px;

                &.itsover {
                    .token {
                        opacity: 0.55;
                    }

                    .date {
                        > span:first-child {
                            opacity: 0.55;
                        }
                    }

                    .owari {
                        color: #b625f9;
                    }
                }

                &.active {
                    background-image: linear-gradient(transparent, #b625f95a);
                }
            }

            .date {
                font-size: 0.6rem;
            }

            @media (min-width: 576px) {
                .date {
                    font-size: 0.8rem;
                }
            }
        }

        .token {
            padding-left: 5px;

            > div {
                display: inline-block;
                padding-left: 5px;
            }
        }

        .more-info {
            color: #b625f9;
        }

        @media (max-width: 1799px) {
            .img-mk-home {
                // transform: scale(1.5);
                margin-left: -100px;
            }
        }
        @media (max-width: 1699px) {
            .img-mk-home {
                // transform: scale(1.4);
            }
        }
        @media (max-width: 1499px) {
            .img-mk-home {
            }
        }
        @media (max-width: 1299px) {
            .img-mk-home {
                margin-top: -100px;
                margin-left: -90px;
            }
        }
        @media (max-width: 1199px) {
            .img-mk-home {
                margin-top: -50px;
                margin-left: -50px;
                // transform: scale(1.3);
            }
            .section-title {
                font-size: 3em;
            }
            .section-title-sg {
                font-size: 1em;
            }
            .section-title-sg2 {
                font-size: 1em;
            }
            .line {
                width: 150px;
                margin: 25px 0px;
            }
        }
        @media (max-width: 991px) {
            padding: 50px 0;
            overflow: auto;
            .section-title,
            .section-title-sg,
            .section-title-sg2,
            img {
                width: 100%;
            }
            .section-title,
            .section-title-sg,
            .section-title-sg2 {
                position: relative;
                z-index: 10;
            }
            .section-title {
                text-align: center;
                font-size: 2.5em;
                text-shadow: 2px 0 0 rgba(182, 37, 249, 0.7),
                    2px 0 0 rgba(182, 37, 249, 1),
                    2px 1px 0 rgba(182, 37, 249, 1),
                    1px -1px 0 rgba(182, 37, 249, 1);
            }
            .section-title-sg {
                text-align: left;
                font-size: 1.2em;
            }
            .section-title-sg2 {
                text-align: left;
                font-size: 1.2em;
            }
            .line {
                margin: 30px auto;
            }
            .cs-mk {
                //position: absolute;
                //top: 0;
                //max-width: 400px;
                //right: 0;
                //bottom: 0;
                //margin: auto;
                //display: flex;
                //align-items: center;
                //z-index: 0;
                //
                //.img-mk-home {
                //    transform: scale(1);
                //    margin-top: 30px;
                //    margin-left: 0px;
                //}
            }
            .cs-tablet {
                max-width: 90%;

                .box-count {
                    max-width: 170px;
                }
            }
        }
        @media (max-width: 575px) {
            overflow: auto;
            height: auto;
            padding: 30px 0;
            .section-title {
                font-size: 2em;
            }
            .section-title-sg {
                font-size: 0.9em;
            }
            .section-title-sg2 {
                font-size: 0.9em;
            }
            .cs-mk {
                // display: none;
                position: unset;
            }
            .cs-tablet {
                width: 100%;
                max-width: 100%;
                padding: 5px;
            }
            .right,
            .left {
                flex: 1 1 auto;
                width: fit-content;
            }
            .right {
                display: flex;
                justify-content: flex-end;
            }
        }
        @media (max-width: 374px) {
            .section-title-sg {
                font-size: 0.8em;
            }
            .section-title-sg2 {
                font-size: 0.8em;
            }
        }
        @media (min-width: 1366px) {
            .slick-arrow {
                &.slick-next {
                    right: 5%;
                }

                &.slick-prev {
                    left: 5%;
                }
            }
        }
    }

    .section-2 {
        // background: url('~@/assets/images/airdrop/bg-1.jpg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center center;
        background-attachment: fixed;
        overflow: initial;
        padding: 100px 0px;
        min-height: 70vh;
        box-shadow: 0px 25px 20px #001326 inset, 0px -0 0 #001326 inset;
        height: 100%;

        .section-title {
            font-size: 2.2em;
            letter-spacing: 2px;
            text-shadow: 2px 0 0 rgba(182, 37, 249, 1),
                2px 0 0 rgba(182, 37, 249, 0.8),
                2px 1px 0 rgba(182, 37, 249, 0.8),
                1px -1px 0 rgba(182, 37, 249, 0.8);
            text-transform: uppercase;
            text-align: center;
            @media (max-width: 991px) {
                font-size: 2.5em;
            }
            @media (max-width: 480px) {
                font-size: 2em;
            }
        }

        .container {
            position: relative;
            z-index: 10;

            .box-preSale {
                max-width: 900px;
                width: 100%;
                min-height: 400px;
                padding: 20px;
                margin: 30px auto 50px;

                .header-preSale {
                    background: linear-gradient(
                        180deg,
                        #00172a 0%,
                        #00000b 100%
                    );
                    border: 1px solid #b625f9;
                    width: 70%;
                    min-width: fit-content;
                    max-width: 768px;
                    padding: 0 40px;
                    transform: skewX(-20deg);
                    height: 60px;
                    position: relative;
                    z-index: 20;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 auto -30px;

                    .section-title {
                        transform: skewX(20deg);
                    }
                }

                .body-preSale {
                    position: relative;
                    z-index: 19;
                    margin-top: 0px;
                    padding: calc(5% + 20px) 7%;
                    padding-top: 60px;
                    border-radius: 18px;
                    border-top: 1.5px solid #db00ff;
                    border-right: 1.5px solid #db00ff;
                    border-bottom: 1.5px solid #db00ff;
                    background: linear-gradient(
                        105deg,
                        rgba(43, 0, 135, 0) -3.07%,
                        rgba(90, 12, 126, 0.38) 49.04%,
                        rgba(0, 0, 0, 0.63) 97.84%
                    );
                    box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
                    backdrop-filter: blur(319px);

                    .form-group {
                        margin: 15px auto;
                        max-width: 580px;
                        width: 100%;

                        .label {
                            color: #fff;
                            font-size: 1.2em;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            margin-bottom: 5px;
                            font-weight: 600;
                            flex-wrap: wrap;
                            gap: 15px;

                            .label-main {
                                font-size: 1.4em;
                                font-family: 'HHBI';
                                line-height: 0;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                flex-shrink: 0;

                                img {
                                    padding-right: 10px;
                                    width: 40px;
                                }
                            }

                            .price {
                                font-family: 'HHBI';
                            }

                            @media (max-width: 374px) {
                                flex-direction: column;
                                align-items: flex-start;
                            }
                        }

                        input {
                            flex: 1 1 auto;
                            margin: 0 15px 0 0;
                            height: 45px;
                            background: linear-gradient(
                                180deg,
                                #00172a 0%,
                                #00000b 100%
                            );
                            border: 1px solid #b625f9;
                            color: #fff;
                            font-weight: 600;
                            width: 100%;
                            padding: 0 15px;
                        }

                        .buy-HBG {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin: 40px auto 10px;
                            background: linear-gradient(
                                90deg,
                                #5a41bd 0%,
                                #d629f2 100%
                            );
                            border-radius: 7px;
                            width: 70%;
                            max-width: 375px;

                            padding: 2px;

                            a,
                            button {
                                color: #000;
                                border-radius: 5px;
                                font-weight: 600;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border: 1.5px solid #fff;
                                padding: 0;
                                height: 100%;
                                height: 35px;
                                width: 100%;
                                font-family: 'HHBI';
                                font-size: 1.6em;
                                line-height: 0;
                                background: transparent;
                                box-shadow: 0px 0px 5px #b625f9;
                                text-transform: uppercase;

                                &:hover,
                                &:focus-visible,
                                &:visited {
                                    background: #00172a6b;
                                    color: #fff;
                                }
                            }
                        }
                        .address {
                            color: white;
                            font-size: 18px;
                            font-weight: 700;
                            word-wrap: break-word;
                            font-family: 'HHBI';
                            .note {
                                color: #b625f9;
                                font-size: 16px;
                                padding-top: 15px;
                            }
                        }
                    }

                    .btn-change {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin: 10px auto;
                        background: transparent;
                        border: 0;
                        box-shadow: unset;
                        border-radius: 7px;
                        width: 50px;
                        height: 50px;
                        padding: 0;
                        border-radius: 50%;
                        // background: linear-gradient(
                        //     90deg,
                        //     #00ffa9 0%,
                        //     #00b1ff 100%
                        // );
                        img {
                            width: 100%;
                        }
                    }

                    .option {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-wrap: wrap;

                        button {
                            background: linear-gradient(
                                180deg,
                                #00172a 0%,
                                #00000b 100%
                            );
                            padding: 2px 5px !important;
                            margin: 5px;
                            height: 35px;
                            color: #fff;
                            font-family: 'HHBI';
                            width: fit-content;
                            min-width: 90px;
                            font-size: 1.1em;
                            line-height: 0;

                            &:hover {
                                background: linear-gradient(
                                    90deg,
                                    #5a41bd 0%,
                                    #d629f2 100%
                                );
                                // color: #00000b;
                            }
                        }
                    }
                    .stage-info {
                        position: absolute;
                        right: 10px;
                        top: 30px;
                        color: #b625f9;
                    }
                    @media (min-width: 768px) {
                        border-radius: 40px;
                        padding-top: calc(5% + 20px);
                        .stage-info {
                            top: 10px;
                        }
                    }
                }
            }

            .box-info-presale {
                // background: linear-gradient(0deg, #b625f9 -60%, #001326 100%);
                display: flex;
                align-items: center;
                justify-content: center;
                min-height: 140px;
                height: 100%;
                width: 100%;
                max-width: 575px;
                padding: 5% 10%;
                // border-radius: 10px;
                @media (max-width: 480px) {
                    min-height: 100px;
                }

                .icons {
                    flex: 0 0 70px;
                    width: 70px;
                    height: 100%;

                    img {
                        width: 50px;
                        height: auto;
                    }
                }

                .text {
                    flex: 1 1 auto;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-direction: column;
                    height: 100%;

                    .text-title {
                        color: #b625f9;
                        font-size: 1.4em;
                        line-height: 120%;
                        font-family: 'HHBI';
                        font-weight: 600;
                        margin-bottom: 15px;
                        text-transform: capitalize;
                    }

                    .text-content {
                        color: #ffffff;
                        font-size: 1.6em;
                        line-height: 120%;
                        font-family: 'HHBI';
                    }

                    &:not(.button-claim) {
                        padding-bottom: 50px;
                    }
                }

                button.claim {
                    background: linear-gradient(
                        180deg,
                        #00172a 0%,
                        #00000b 100%
                    );
                    padding: 2px 5px !important;
                    margin: 10px auto 5px;
                    height: 38px;
                    color: #fff;
                    font-family: 'HHBI';
                    width: 150px;
                    font-size: 1.1em;
                    line-height: 0;

                    &:hover {
                        background: linear-gradient(
                            90deg,
                            #00ffaa 0%,
                            #00b3ff 100%
                        );
                        color: #00000b;
                    }
                }
            }

            &.box-unlock {
                .section-title {
                    font-size: 2.7em;
                }

                .unlock-tabs {
                    display: flex;

                    button {
                        transform: skew(-10deg);
                        color: #e4e4e7;
                        outline: none;
                        background-image: linear-gradient(
                            50deg,
                            #27272a 14.98%,
                            #3f3f46 106.07%
                        );
                        position: relative;
                        width: 50%;
                        max-width: 150px;
                        height: 40px;

                        &::after {
                            content: '';
                            position: absolute;
                            inset: 0;
                            background-image: linear-gradient(
                                50deg,
                                #5a41bd 14.98%,
                                #d629f2 106.07%
                            );
                            z-index: -1;
                            opacity: 0;
                            transition: 0.2s;
                        }

                        &:hover,
                        &:focus {
                            &::after {
                                opacity: 0.4;
                            }
                        }

                        &:active {
                            &::after {
                                opacity: 0.8;
                            }
                        }

                        &.active {
                            &::after {
                                opacity: 1;
                            }
                        }
                    }
                }

                margin-top: 10vh;

                .box-table {
                    background: red;
                    background-size: 100% 100%;
                    width: 100%;
                    height: auto;
                    padding: 3%;
                    margin: 0 auto;

                    &.mb {
                        display: none;
                        background: #021527b3;
                        border: 2px solid #b625f9;
                        border-radius: 15px;
                        background-size: 100% 100%;
                        padding: 0;

                        .box-content {
                            .item {
                                display: flex;
                                justify-content: center;
                                min-height: 60px;
                                align-items: center;
                                padding: 15px 25px;
                                flex-direction: column;

                                .item-content {
                                    display: flex;
                                    justify-content: space-between;
                                    width: 100%;
                                    border-top: 1px solid #fff;
                                    min-height: 60px;
                                    align-items: center;

                                    &:nth-child(1) {
                                        border-top: 0;

                                        .right {
                                            .section-title {
                                                color: #00fff8;
                                            }
                                        }
                                    }

                                    .left,
                                    .right {
                                        width: 50%;
                                    }

                                    .section-title-sg,
                                    .section-title {
                                        font-size: 18px;
                                        margin: 0px;
                                        text-shadow: 0px 0 5px
                                            rgba(182, 37, 249, 0.7);
                                        text-transform: none;
                                        text-align: right;
                                    }

                                    .section-title-sg {
                                        color: #b625f9;
                                        text-align: left;
                                    }
                                }
                            }
                        }
                    }

                    .box-header {
                        display: flex;
                        min-height: 75px;
                        background: rgba(0, 0, 0, 0.5);
                        padding: 0 25px;
                        align-items: center;

                        div {
                            color: #00fff8;
                            text-shadow: 0px 0 5px rgba(0, 252, 245, 0.7);
                            font-size: 1.2em;
                            font-family: 'HHBI';
                            text-transform: uppercase;
                            text-align: left;
                        }

                        .name,
                        .value {
                            flex: 0 0 30%;
                            text-align: center;
                            // max-width: 300px;
                        }

                        .price,
                        .time,
                        .status {
                            flex: 0 0 20%;
                            text-align: center;
                            // max-width: 200px;
                        }

                        .quantity {
                            flex: 1 1 auto;
                        }
                    }

                    .box-content {
                        display: flex;
                        flex-direction: column;

                        .item {
                            display: flex;
                            min-height: 63px;
                            align-items: center;
                            padding: 0 25px;

                            &:nth-child(2n + 2) {
                                background: rgba(0, 0, 0, 0.25);
                            }

                            div {
                                color: #ffffff;
                                text-shadow: 0px 0 5px rgba(0, 252, 245, 0.7);
                                font-size: 1.2em;
                                font-family: 'HHBI';
                                text-align: left;
                            }

                            .name {
                                color: #00fff8;
                            }

                            .name,
                            .value {
                                text-align: center;
                                flex: 0 0 30%;
                            }

                            .price,
                            .time,
                            .status {
                                text-align: center;
                                flex: 0 0 20%;

                                .btn-status {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    background: linear-gradient(
                                        90deg,
                                        #00ffa9 0%,
                                        #00b1ff 100%
                                    );
                                    border-radius: 7px;
                                    width: fit-content;
                                    height: 40px;
                                    padding: 2px 5px;
                                    text-transform: uppercase;
                                    color: #00172a;
                                }
                            }

                            .quantity {
                                flex: 1 1 auto;
                            }

                            .status {
                                justify-content: center;
                                align-items: center;
                                display: flex;
                                text-transform: uppercase;

                                .section-title {
                                    text-transform: uppercase;
                                }

                                @media (max-width: 991px) {
                                    justify-content: flex-end;
                                }
                            }
                        }
                    }

                    @media (max-width: 991px) {
                        &.pc {
                            display: none;
                        }
                        &.mb {
                            display: block;
                        }
                    }
                }
            }

            @media (max-width: 575px) {
                .box-preSale {
                    padding: 0;

                    .body-preSale {
                        background-size: auto;
                        background-position: center center;
                        border: 2px solid #b625f9;
                        border-radius: 5px;
                    }
                }
                .box-info-presale,
                .box-preSale {
                    margin: 15px auto;
                }
            }
        }
    }

    .box-mission {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #b625f9;
        margin-bottom: 15px;
        padding: 10px 10px;
        position: relative;
        height: 100%;
        width: 100%;
        max-width: 475px;
        height: auto;

        &:after,
        &::before {
            z-index: 0;
            content: '';
            width: 6px;
            height: 6px;
            background: #b625f9;
        }

        &:after {
            position: absolute;
            top: 1px;
            right: 1px;
        }

        &::before {
            position: absolute;
            bottom: 1px;
            left: 1px;
        }

        .icons {
            flex: 0 0 70px;
            width: 70px;
            height: 100%;

            img {
                width: 50px;
                height: auto;
            }
        }

        .text {
            flex: 1 1 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            height: 100%;

            .text-title {
                min-height: 40px;
                color: #b625f9;
                font-size: 1.4em;
                line-height: 120%;
                font-family: 'HHBI';
                font-weight: 600;
                margin-bottom: 15px;
                text-transform: capitalize;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .text-content {
                height: calc(100% - 50px);
                color: #ffffff;
                font-size: 1.6em;
                line-height: 120%;
                font-family: 'HHBI';
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &.box-mission-2 {
            max-width: unset;

            .header-box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;

                .text-title {
                    margin-bottom: 0;
                }

                margin-bottom: 15px;
            }

            .pending {
                width: 100%;

                &-content {
                    height: 40px;
                    padding: 0 0.75rem;
                    vertical-align: middle;
                    color: #fff;
                    font-weight: 600;
                    display: flex;
                    font-family: 'Helvetica';
                    justify-content: space-between;
                    align-items: center;
                    border-radius: 5px;

                    &:nth-of-type(even) {
                        box-shadow: inset 0px 0px 2005px rgba(22, 22, 22, 0.5);
                    }

                    &:nth-of-type(odd) {
                        box-shadow: inset 0px 0px 2005px rgba(18, 37, 37, 0.2);
                    }

                    &-left {
                        flex: 1 0 auto;
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;

                        .form-control {
                            background: transparent;
                            border: 0.5px solid #e4e0e0;
                            border-radius: calc(40px / 1.25);
                            height: 40px;
                            max-width: 375px;
                            color: #fff;
                            font-size: 17px;
                            margin-right: 5px;

                            &:is(:focus, .active) {
                                border-color: #b625f9;
                            }
                        }

                        button {
                            border-radius: calc(40px / 1.25);
                            height: 40px;
                            padding: 0 15px !important;
                            max-width: unset;
                            outline: unset !important;
                        }
                    }

                    &-right {
                        flex: 0 0 auto;
                        border-left: 1px solid #b625f9;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #b625f9;
                        padding: 0px 15px;
                        @media (min-width: 992px) {
                            justify-content: flex-start;
                            text-align: left;
                            padding: 0px 40px;
                        }
                    }

                    @media (min-width: 768px) and (max-width: 991px) {
                        flex-direction: column;
                        height: auto;
                        padding: 5px 0;
                        &-right,
                        &-left {
                            flex: unset;
                            width: 100%;
                            justify-content: center;
                            border: 0;
                        }
                    }
                    @media (max-width: 575px) {
                        flex-direction: column;
                        height: auto;
                        padding: 5px 0;
                        &-right,
                        &-left {
                            flex: unset;
                            width: 100%;
                            justify-content: center;
                            border: 0;
                        }
                    }
                }
            }
        }

        &.box-mission-3 {
            max-width: 500px;
            flex-direction: column;

            .header-box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;

                .text-title {
                    margin-bottom: 0;
                }

                margin-bottom: 15px;
            }
        }
    }
}

@keyframes imgHero3 {
    25% {
        transform: translateY(-20px) scale(1);
    }
    50% {
        transform: translateY(0px) scale(1);
    }
    75% {
        transform: translateY(20px) scale(1);
    }
}

@keyframes image-fly02 {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(45deg);
    }
}

#claimUcon {
    .note {
        text-align: center;

        .title {
            color: #b625f9;
            font-size: 17px;
            font-weight: 600;
            margin-bottom: 5px;
        }

        p {
            color: #fff;
            font-weight: 500;

            span {
                color: #b625f9;
                font-size: 17px;
                font-weight: 600;
            }
        }
    }

    .modal-content {
        border-color: #b625f9 #b625f9 #b625f9 transparent;
        background: linear-gradient(
            105deg,
            rgba(43, 0, 135, 0) -3.07%,
            rgba(90, 12, 126, 0.38) 49.04%,
            rgba(0, 0, 0, 0.63) 97.84%
        );
        backdrop-filter: blur(60px);

        .modal-body {
            background: transparent;
        }

        .modal-header {
            background: transparent;
        }

        .form-group {
            width: 80%;
            min-width: 200px;
            margin: 0 auto 15px;
        }

        .form-sl {
            position: relative;

            img {
                height: 15px;
                width: auto;
                position: absolute;
                right: 15px;
                bottom: 0;
                top: 0;
                margin: auto;
            }
        }

        select {
            height: 40px;
            background: linear-gradient(to right, #00172a 0%, #00000b 100%);
            color: #fff;
            border-radius: 0;
            border: 1px solid #b625f9;

            option {
                background: #00000b;
            }
        }

        input {
            margin: 0 auto;
            width: 100%;
            background: linear-gradient(180deg, #00172a 0%, #00000b 100%);

            &:read-only {
                background: linear-gradient(180deg, #3f383e 0%, #360b39 100%);
            }
        }

        label {
            width: 100%;
            min-width: 200px;
            margin: 0 auto;
            display: block;
            color: #fff;
            font-size: 18px;
            font-weight: 600;
        }
    }
}

.box-token {
    width: 100%;
    min-height: 150px;
    border: 1px solid #b625f9;
    margin-bottom: 15px;
    padding: 10px 10px;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    &:after,
    &::before {
        z-index: 0;
        content: '';
        width: 6px;
        height: 6px;
        background: #b625f9;
    }

    &:after {
        position: absolute;
        top: 1px;
        right: 1px;
    }

    &::before {
        position: absolute;
        bottom: 1px;
        left: 1px;
    }

    &.main-box {
        min-height: 240px;
        padding: 15px 25px;
        @media (max-width: 413px) {
            padding: 15px 10px;
        }
    }

    &-header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px;

        &.border-b {
            border-bottom: 1px solid #fff;
            padding: 0 0 15px;
        }

        &-image {
            margin-right: 20px;

            img {
                width: 29px;
                height: 29px;
            }
        }

        &-title {
            font-family: 'HHBI';
            font-style: italic;
            font-weight: 400;
            font-size: 20px;
            line-height: 33px;
            letter-spacing: 0.75px;
            color: #b625f9;
        }
    }

    &-body {
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin: 15px 0;
        flex-wrap: wrap;

        &-image {
            position: relative;
            margin-right: 15px;
            flex: 0 0 65px;

            img {
                width: 43px;
                height: 43px;
                margin-right: 15px;
            }

            &::after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                width: 5px;
                height: 5px;
                background: #b625f9;
                border-radius: 50%;
            }
        }

        &-amount {
            flex: 1 1 auto;
            font-family: 'Epilogue';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            color: #b625f9;
        }

        &-symbol {
            flex: 1 1 auto;
            font-family: 'HHBI';
            font-style: italic;
            font-weight: 400;
            font-size: 16px;
            letter-spacing: 0.75px;
            color: #b625f9;
            text-align: center;
        }
    }

    &-footer {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;

        a {
            .box-wallet-footer-button {
                max-width: unset;
            }
        }

        &-button {
            margin: 10px;
            background: #55555550;
            border-radius: 54px;
            height: 44px;
            width: 160px;
            border: none;
            box-shadow: unset;
            outline: none !important;
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: calc(50% - 20px);

            &.active,
            &:hover,
            &:focus,
            &:active {
                background: #b625f9;

                * {
                    color: #fff !important;
                }
            }

            &-text {
                font-family: 'HHBI';
                font-style: italic;
                font-weight: 400;
                font-size: 15px;
                line-height: 20px;
                letter-spacing: 0.75px;
                color: #dcdcdc;
            }

            &-image {
                margin-right: 12px;

                img {
                    width: 17px;
                    height: auto;
                    max-height: 17px;
                    object-fit: scale-down;
                }
            }
        }
    }
}

.unlock__history {
    &__box__table {
        margin-top: 60px;

        .table-vac {
            table {
                th,
                tr,
                td {
                    border: 0;
                    min-width: 100px;

                    &:first-child {
                        min-width: unset;
                        padding: 0;
                        text-align: center;
                        vertical-align: middle;
                    }
                }

                th {
                    font-family: 'Work Sans';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    letter-spacing: 0.01em;
                    color: #f0f0f0;
                    padding: 24px;
                    position: relative;
                    text-align: center !important;

                    &::after {
                        position: absolute;
                        right: 0;
                        top: 0;
                        bottom: 0;
                        width: 2px;
                        height: 16px;
                        content: '';
                        margin: auto;
                        background: #b625f9;
                    }

                    &:last-child {
                        &::after {
                            background: transparent;
                        }
                    }
                }

                tbody {
                    tr,
                    td {
                        font-family: 'Work Sans';
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 24px;
                        /* or 150% */
                        vertical-align: middle;
                        letter-spacing: 0.01em;

                        /* GrayScale/Input BG */
                        padding-top: 15px;
                        padding-bottom: 15px;
                        padding-left: 25px;
                        color: #f0f0f0;
                    }

                    tr:nth-of-type(odd) {
                        background-color: #49505757;
                        border-radius: 1px;
                    }

                    tr {
                        &:hover {
                            background-color: rgba(0, 255, 255, 0.05);
                            border-radius: 1px;
                            color: #f0f0f0;
                        }
                    }
                }
            }

            .btn-open-detail {
                padding: 0;
                box-shadow: none !important;
                outline: none !important;
                border: 0 !important;
                height: 25px;
                width: 25px;
                background: #00172a;
                border-radius: 50%;
                margin: auto 5px !important;

                img {
                    width: 15px;
                    height: 15px;
                }

                &.open {
                    width: 25px;
                    height: 25px;

                    img {
                        width: 25px;
                        height: 25px;
                    }
                }
            }

            .b-table-details {
                background: #001326c4 !important;
            }

            .detail__table__item {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 5px 10px;
                text-transform: capitalize;

                &-left {
                    margin-right: 15px;
                    padding-right: 15px;
                }

                &-right {
                }
            }
        }
    }
}

#schedule {
    color: #f4f4f5;

    .entire-schedule {
        display: grid;

        > div {
            border-bottom: 1px solid #b625f9;
            padding: 0px 5px;
        }

        .headline {
            font-size: 1.4rem;
            font-weight: 600;
        }

        .duration {
            font-size: 0.8rem;
        }

        .days {
            .date {
                font-size: 0.8rem;
            }
        }

        .day {
            padding: 12px 0px;

            span {
                font-style: italic;
                font-weight: 600;
            }
        }

        @media (min-width: 992px) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
            > div {
                border-bottom: none;
                border-left: 1px solid #b625f9;
                padding: 0px 5px;
            }
            .day {
                padding: 8px 0px;
            }
        }
    }
}

#stage-details {
    .modal-content .modal-body {
        background: #00000085;
        border: 1px solid #9f02dd;
        backdrop-filter: blur(10px);
        border-radius: 12px;
        padding: 20px 10px 10px;
        @media (min-width: 768px) {
            padding: 25px 20px 20px;
        }
    }
    .stage {
        color: white;
        .head {
            font-size: 20px;
            font-weight: 700;
            text-transform: uppercase;
        }
        .content {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding: 8px 0px;
            > div:first-child {
                color: #7d68b6;
            }
            > div:last-child {
                text-align: right;
            }
        }
        .close {
            color: white;
            position: absolute;
            top: 2px;
            right: 10px;
            z-index: 1;
        }
    }
}
</style>
