<script>
import { mapGetters } from 'vuex';
import { MetaMask } from '@/components/auth';
import { filter } from 'lodash';

export default {
    components: { MetaMask },
    data() {
        return {
            showMenu: false,
            showDrop: false,
        };
    },
    computed: {
        ...mapGetters({
            isLogin: 'auth/loggedIn',
            Balance: 'wallet/Balance',
            UserInfo: 'info/UserInfo',
        }),
        getMenu() {
            // listTab
            const self = this;
            return filter(this.listTab, (data) => {
                return !data.short.includes(self.hidden);
            });
        },
    },
    watch: {
        showMenu(newVal) {
            if (newVal) document.documentElement.style.overflow = 'hidden';
            else document.documentElement.style.overflow = 'auto';
        },
        $route(to, from) {
            if (to.name !== from.name) {
                if (window.innerWidth <= 991) {
                    this.showMenu = false;
                }
            }
        },
    },
    mounted() {
        if (this.$route.name === 'Account') {
            this.showAccount = false;
        } else {
            this.showAccount = true;
        }
    },
    methods: {
        logout() {
            this.$store.commit('auth/LOGOUT_SUCCESS');
        },
    },
};
</script>
<template>
    <header id="top-bar">
        <b-container id="newHeader" fluid class="header-container desktop">
            <div id="menu">
                <div class="nav-logo">
                    <img src="~@/assets/images/logo/logov2.png" alt="" />
                </div>
                <div
                    class="list-navbar col-xl-6 p-0 d-flex justify-content-center"
                >
                    <a href="https://ucon.social/" target="_blank"> Home </a>
                    <router-link :to="{ name: 'Vesting' }" class="new">
                        Vesting
                        <span class="popping">NEW</span>
                    </router-link>
                    <router-link :to="{ name: 'Member' }" v-if="isLogin">
                        Referal Link
                    </router-link>
                    <router-link :to="{ name: 'Account' }" v-if="isLogin">
                        Account
                    </router-link>
                    <a href="https://vip.ucon.social/" target="_blank"> VIP </a>
                    <a href="https://mining.ucon.social/" target="_blank">
                        Mining
                    </a>
                </div>

                <div class="nav-user topbar-nv" v-if="isLogin">
                    <div v-if="isLogin" class="header-balance d-flex mr-2">
                        <p class="total-balance">
                            {{ TruncateToDecimals2(Balance.ucon, '', 2) }}
                            <span class="active-children px-2">UCON</span>
                        </p>
                        <a href="#" target="_self">
                            <img
                                src="../../assets/images/icons/icon-add.svg"
                                alt=""
                        /></a>
                    </div>
                    <div class="position-relative">
                        <button
                            @click="showDrop = !showDrop"
                            class="header-avatar"
                        >
                            <img
                                v-if="UserInfo && UserInfo.Avatar"
                                :key="`avatar-${UserInfo.Avatar}`"
                                :src="UserInfo.Avatar"
                                alt=""
                            />
                            <img
                                class="null-avatar"
                                v-else
                                src="~@/assets/images/icons/user.png"
                                alt=""
                            />
                        </button>
                        <b-button
                            v-if="showDrop"
                            class="logout"
                            variant="danger"
                            @click="logout"
                        >
                            Logout
                        </b-button>
                    </div>
                </div>
                <router-link :to="{ name: 'Login' }" class="login" v-else>
                    <div class="text">Login</div>
                </router-link>
            </div>
        </b-container>
        <b-container class="header-container mobile">
            <div id="nav-mobile">
                <div class="links">
                    <router-link
                        :to="{ name: 'Vesting' }"
                        class="menu-item new"
                    >
                        Vesting
                        <span>NEW</span>
                    </router-link>
                    <router-link
                        :to="{ name: 'Member' }"
                        class="menu-item"
                        v-if="isLogin"
                    >
                        Referal Link
                    </router-link>
                    <router-link
                        v-if="isLogin"
                        :to="{ name: 'Account' }"
                        class="menu-item"
                    >
                        Account
                    </router-link>
                    <router-link
                        :to="{ name: 'Login' }"
                        class="menu-item"
                        v-else
                    >
                        Login
                    </router-link>
                </div>
                <div class="toggle-menu-mobile mx-1">
                    <b-button @click="showMenu = !showMenu">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            v-if="!showMenu"
                        >
                            <path
                                fill="none"
                                stroke="currentColor"
                                stroke-linecap="round"
                                stroke-width="1.5"
                                d="M4 7h3m13 0h-9m9 10h-3M4 17h9m-9-5h16"
                            />
                        </svg>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 256 256"
                            v-if="showMenu"
                        >
                            <path
                                fill="currentColor"
                                d="M208.49 191.51a12 12 0 0 1-17 17L128 145l-63.51 63.49a12 12 0 0 1-17-17L111 128L47.51 64.49a12 12 0 0 1 17-17L128 111l63.51-63.52a12 12 0 0 1 17 17L145 128Z"
                            />
                        </svg>
                    </b-button>
                </div>
                <transition
                    name="slide"
                    enter-active-class="animate__animated animate__slideInRight"
                    leave-active-class="animate__animated animate__slideOutRight"
                >
                    <div class="sidebar-menu" v-if="showMenu">
                        <div class="break-point break-point-2 mb-1"></div>
                        <div class="nav-logo justify-content-center">
                            <img src="~@/assets/images/logo/logo.png" alt="" />
                        </div>
                        <template v-if="isLogin">
                            <div class="break-section mt-4">
                                <div class="name text-white">User</div>
                            </div>
                            <div class="nav-user" v-if="isLogin">
                                <div class="balances w-100">
                                    <div class="icons">
                                        <img
                                            src="~@/assets/images/logo/UCN.png"
                                            alt=""
                                        />
                                    </div>
                                    <div class="amount">
                                        {{
                                            TruncateToDecimals2(
                                                Balance.ucon,
                                                '',
                                                2,
                                            )
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div class="list-sidebar-item">
                                <a
                                    href="https://ucon.social/"
                                    target="_blank"
                                    class="sidebar-item"
                                >
                                    <div class="text">
                                        <div class="title">Home</div>
                                    </div>
                                </a>
                                <a
                                    href="https://vip.ucon.social/"
                                    target="_blank"
                                    class="sidebar-item"
                                >
                                    <div class="text">
                                        <div class="title">VIP</div>
                                    </div>
                                </a>
                                <a
                                    href="https://mining.ucon.social/"
                                    target="_blank"
                                    class="sidebar-item"
                                >
                                    <div class="text">
                                        <div class="title">Mining</div>
                                    </div>
                                </a>
                                <a
                                    href="javascript:void(true);"
                                    @click="logout"
                                    class="sidebar-item"
                                >
                                    <div class="text logout">
                                        <div class="title">Logout</div>
                                    </div>
                                </a>
                            </div>
                        </template>
                    </div>
                </transition>
            </div>
        </b-container>
    </header>
</template>
<style lang="scss" scoped>
$top-bar-height: 90px;
$top-bar-height-mb: 60px;
$side-bar-width: 320px;
$top-bar-color: linear-gradient(180deg, #00172a 0%, #00000b 100%);
$top-text-color: #ffffff;
$top-text-active: '';
.desktop {
    display: none;
}

@media (min-width: 992px) {
    .desktop {
        display: block;
    }
    .mobile {
        display: none;
    }
}

#top-bar {
    position: sticky;
    top: 0;
    height: $top-bar-height-mb;
    background: rgba(255, 255, 255, 0.06);
    border-bottom: 1px solid rgba(170, 170, 170, 0.42);
    @media (max-width: 991px) {
        background: rgba(255, 255, 255, 0.06);
        box-shadow: 5px 0px 15px 5px rgba(0, 0, 0, 0.1);
    }
    width: 100%;
    z-index: 1030;
    backdrop-filter: blur(19px);
    @media (min-width: 992px) {
        height: $top-bar-height;
    }
    @media (max-width: 575px) {
        position: fixed;
        z-index: 1041;
        bottom: 0;
        top: auto;
    }

    .login {
        border-radius: 72px;
        background: linear-gradient(50deg, #5a41bd 14.98%, #d629f2 106.07%);
        box-shadow: 0px 2px 11.6px 1px rgba(176, 176, 176, 0.25) inset;
        padding: 5px 18px;

        &:hover {
            text-decoration: none;
        }

        .text {
            color: white;
            font-size: 20px;
        }

        .icons {
            width: 35px;
            height: 35px;
            margin: auto 5px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        @media screen and (max-width: 767px) {
            display: none !important;
        }
    }
}

.desktop {
    &.header-container {
        padding: 0 2vw;
        @media (max-width: 1399px) {
            max-width: 100%;
        }

        #menu {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: $top-bar-height;
            font-family: 'HHBI';

            .nav-logo {
                width: fit-content;
                max-width: 250px;
                min-width: 120px;
                height: 100%;
                padding: 5px;
                max-height: 50px;

                img {
                    height: 100%;
                    width: auto;
                }
            }

            .nav-menu {
                flex-grow: 1;
                flex: auto;
                height: 100%;
                display: flex;
                justify-content: center;
                padding: 0 0vw;
                overflow: hidden;

                .menu-item {
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-width: 100px;
                    flex: 1 1 auto;
                    max-width: 210px;
                    text-decoration: none;
                    cursor: pointer;

                    .icons {
                        width: 35px;
                        height: 35px;
                        margin: auto 5px;
                        min-width: 35px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .text {
                        font-size: 21px;
                        color: $top-text-color;
                        text-transform: capitalize;
                    }
                }
            }

            .nav-user {
                width: fit-content;
                max-width: 550px;
                min-width: 120px;
                height: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                position: relative;

                .account {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-width: 100px;
                    text-decoration: none;
                    cursor: pointer;
                    margin: auto 0 auto 15px;

                    .icons {
                        width: 35px;
                        height: 35px;
                        margin: auto 5px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .text {
                        font-size: 21px;
                        color: $top-text-color;
                        text-transform: capitalize;
                    }
                }

                .balances {
                    width: 250px;
                    //background: url('~@/assets/images/button/balance.png');
                    background-size: 100% 100%;
                    height: 40px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 0 0 5px;

                    .icons {
                        flex: 0 0 27px;
                        width: 27px;
                        height: 27px;
                        background: black;
                        border-radius: 50%;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .amount {
                        color: #fff;
                        font-size: 17px;
                    }

                    .buy-btn {
                        flex: 0 0 70px;
                        //background: url('~@/assets/images/button/buy.png');
                        background-size: 100% 100%;
                        width: 70px;
                        color: black;
                        font-weight: 900;
                        font-size: 20px;
                        padding: 0;
                        height: calc(100% - 5px);
                        outline: none !important;
                        border: 0;
                        box-shadow: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &:focus,
                        &:hover {
                            transform: scale(1.1);
                        }
                    }
                }
            }

            @media (max-width: 1500px) {
                .nav-user {
                    .account {
                        .icons {
                            max-width: 24px;
                            height: auto;
                            width: 24px;
                            min-width: 24px;
                        }

                        .text {
                            font-size: 18px;
                        }
                    }

                    .balances {
                        width: fit-content;

                        .amount {
                            padding: 0 5px;
                            font-size: 15px;
                        }
                    }
                }
            }
            @media (max-width: 1280px) {
                .nav-menu {
                    .menu-item {
                        flex-direction: column;
                        min-width: 85px;

                        .icons {
                            margin: 0;
                            max-width: 24px;
                            height: auto;
                            width: 24px;
                            min-width: 24px;
                        }

                        .text {
                            font-size: 17px;
                        }
                    }
                }
                .nav-user {
                    .account {
                        flex-direction: column;
                        min-width: 85px;

                        .icons {
                            margin: 0;
                            max-width: 24px;
                            height: auto;
                            width: 24px;
                            min-width: 24px;
                        }

                        .text {
                            font-size: 17px;
                        }
                    }

                    .balances {
                        width: fit-content;

                        .amount {
                            padding: 0 5px;
                            font-size: 15px;
                        }
                    }
                }
            }
            @media (max-width: 1200px) {
                .nav-logo {
                    display: flex;
                    align-items: center;
                    min-width: unset;
                    max-width: unset;

                    img {
                        width: 70px;
                        height: auto;
                    }
                }
                .nav-menu {
                    padding: 0 20px;

                    .menu-item {
                        max-width: 175px;

                        .text {
                            font-size: 16px;
                        }
                    }
                }
                .nav-user {
                    width: auto;
                    min-width: unset;

                    .balances {
                        width: auto;

                        .amount {
                            font-size: 15px;
                            padding: 0 7px;
                        }

                        .buy-btn {
                        }
                    }

                    .account {
                        .text {
                            font-size: 15px;
                        }
                    }
                }
            }
            @media (max-width: 1560px) and (min-width: 992px) {
                .nav-menu {
                    padding: 0 20px 0 5px;

                    .menu-item {
                        // max-width: 175px;
                        flex: 1 1 auto;

                        .icons {
                            max-width: 24px;
                            height: auto;
                            width: 24px;
                            min-width: 24px;
                        }
                    }
                }
            }
            @media (max-width: 1200px) and (min-width: 992px) {
                .nav-menu {
                    .menu-item {
                        .text {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}

.mobile {
    &.header-container {
        @media (max-width: 1399px) {
            max-width: 100%;
            padding: 0;
        }

        #nav-mobile {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: $top-bar-height-mb;
            font-family: 'HHBI';

            .nav-logo {
                display: flex;
                align-items: center;
                min-width: unset;
                max-width: unset;

                img {
                    width: 90px;
                    height: auto;
                }
            }

            .links {
                display: flex;
                flex-grow: 1;
                justify-content: space-around;
            }

            .login {
                display: flex !important;
                clip-path: unset;
            }

            .menu-item {
                // overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: unset;
                flex: 1 1 auto;
                flex-direction: column;
                cursor: pointer;
                height: 100%;
                color: white;
                text-decoration: none;
                position: relative;
                &.new {
                    span {
                        position: absolute;
                        z-index: 1;
                        bottom: calc(100% + 15px);

                        padding: 4px;
                        border-radius: 3px;
                        font-size: 12px;
                        color: white;
                        line-height: 0.8;
                        background-color: #b625f9;
                        animation: blinking 2s step-start 0s infinite;
                        @keyframes blinking {
                            50% {
                                opacity: 0;
                            }
                        }
                        &::after {
                            content: '';
                            position: absolute;
                            top: 100%;
                            border-width: 5px;
                            border-style: solid;
                            border-color: #b625f9 transparent transparent
                                transparent;
                            left: 50%;
                            transform: translateX(-50%);
                        }
                        @media (min-width: 576px) {
                            bottom: unset;
                            top: calc(100% + 15px);
                            &::after {
                                top: unset;
                                bottom: 100%;
                                border-color: transparent transparent #b625f9
                                    transparent;
                            }
                        }
                    }
                }

                &:hover {
                    text-decoration: none;
                }

                .icons {
                    width: 25px;
                    height: 25px;
                    margin: 0 auto 5px !important;
                    min-width: 25px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .text {
                    font-size: 11px;
                    color: $top-text-color;
                    text-transform: capitalize;
                }
            }

            .nav-user {
                width: auto;
                min-width: unset;
                max-width: 550px;
                height: auto;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                position: relative;

                &.topbar-nv {
                    @media (max-width: 380px) {
                        display: none !important;
                    }
                    @media screen and (max-width: 767px) {
                        .account {
                            display: none !important;
                        }
                    }
                }

                .account {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-width: 100px;
                    text-decoration: none;
                    cursor: pointer;
                    margin: auto 0 auto 15px;

                    .icons {
                        width: 35px;
                        height: 35px;
                        margin: auto 5px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .text {
                        font-size: 15px;
                        color: $top-text-color;
                        text-transform: capitalize;
                    }
                }

                .balances {
                    width: auto;
                    //background: url('~@/assets/images/button/balance.png');
                    background-size: 100% 100%;
                    height: 40px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0 0 0 5px;
                    background-color: #1a1044;
                    border-radius: 8px;
                    border: 1px solid #5a41bd;

                    .icons {
                        flex: 0 0 27px;
                        width: 27px;
                        height: 27px;
                        background: black;
                        border-radius: 50%;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .amount {
                        color: #fff;
                        font-size: 15px;
                        padding: 0 7px;
                    }

                    .buy-btn {
                        flex: 0 0 70px;
                        //background: url('~@/assets/images/button/buy.png');
                        background-size: 100% 100%;
                        width: 70px;
                        color: black;
                        font-weight: 900;
                        font-size: 15px;
                        padding: 0;
                        height: calc(100% - 5px);
                        outline: none !important;
                        border: 0;
                        box-shadow: none;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        &:focus,
                        &:hover {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }
        .toggle-menu-mobile {
            button {
                background: #06284e;
                height: 45px;
                width: 45px;
                border: 0;
                padding: 0;

                img {
                    height: 30px;
                    width: auto;
                }
            }
        }
    }

    .sidebar-menu {
        position: fixed;
        top: $top-bar-height-mb;
        right: 0;
        bottom: 0;
        width: $side-bar-width;
        background: #00000b;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        z-index: 2300;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0 10px;
        height: 100%;
        min-height: calc(100vh - 60px);
        @media (max-width: 575px) {
            top: auto;
            bottom: 60px;
            width: 100%;
            height: calc(100vh - 60px);
        }

        .nav-menu {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;

            .menu-item {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                height: 50px;
                text-decoration: none;
                padding: 0 15px;
                margin: 5px auto;
                cursor: pointer;

                .icons {
                    width: 30px;
                    height: 30px;
                    margin: auto 10px;
                    min-width: 30px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .text {
                    font-size: 17px;
                    color: $top-text-color;
                    text-transform: capitalize;
                }

                &.active {
                    //background: url('~@/assets/images/button/active-menu.jpg');
                }
            }
        }

        .break-point {
            background: #b625f9a4;
            height: 3px;
            width: 100%;
            margin: 20px 0;

            &.break-point-2 {
                height: 1px;
            }
        }

        .break-section {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .name {
                color: #b625f9;
                font-size: 19px !important;
                flex: 0 0 max-content;
            }

            &::before,
            &::after {
                height: 1px;
                display: flex;
                flex: 1 1 auto;
                margin-left: 5px;
                background: #b625f9;
                content: '';
            }

            &::before {
                margin-right: 5px;
            }
        }

        .nav-user {
            width: 100% !important;
            justify-content: flex-start !important;
            flex-direction: column;

            .balances,
            .account {
                width: 100%;
                margin: 10px auto !important;

                &.isLeft {
                    margin: 10px 0 10px 25px !important;
                    width: max-content;
                }
            }

            .account {
                justify-content: flex-start !important;
                padding: 0 15px;

                .icons {
                    margin: 0 10px !important;
                    padding: 3px;

                    img {
                        height: auto !important;
                    }
                }
            }
        }

        @media (max-width: 425px) {
            width: 100%;
        }

        .login-2 {
            margin: 15px auto 0 !important;
            width: 100%;
            max-width: 180px;
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 120px;
            height: 45px;
            text-decoration: none;
            cursor: pointer;
            margin: auto 0 auto 15px;
            background: cyan;
            clip-path: polygon(
                25% 0%,
                75% 0%,
                100% 50%,
                75% 100%,
                25% 100%,
                0% 50%
            );

            .icons {
                width: 35px;
                height: 35px;
                margin: auto 5px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .text {
                color: black;
                font-weight: 900;
                font-size: 20px;
                text-transform: capitalize;
            }

            @media (min-width: 768px) {
                display: none;
            }
        }

        .list-sidebar-item {
            display: flex;
            flex-wrap: wrap;
            max-width: 300px;
            width: 100%;
            margin: 10px auto;

            .sidebar-item {
                flex: 1 1 90px;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 5px 2px;
                max-width: 90px;
                margin: 5px;
                min-height: 60px;
                justify-content: space-between;
                text-decoration: none;

                &.active {
                    border: 1px solid #b625f9;
                    border-radius: 3px;
                }

                .text {
                    font-size: 16px;
                    color: #fff;
                    text-align: center;
                    &.logout {
                        color: #991b1b;
                    }
                }
            }
        }
    }

    .back-drop {
        background: rgba(0, 0, 0, 0.5);
        position: fixed;
        top: $top-bar-height-mb;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 1999;
        @media (max-width: 575px) {
            position: fixed;
            bottom: 60px;
            top: 0;
            height: auto;
        }
    }
    .animate__animated {
        --animate-duration: 0.2s;
    }
}

.slide-enter,
.slide-leave-to {
    transform: scaleY(0);
}

.more-platform {
    @media (max-width: 576px) {
        &.pc {
            display: none;
        }
    }

    button {
        background-color: transparent;
        border: 1px solid #b625f9;
        padding: 0;
        width: 45px;
        height: 45px;
        margin: 0 15px;

        img {
            width: 100%;
            height: auto;
        }
    }
}

.detail-more {
    position: fixed;
    top: 90px;
    left: 10px;
    width: 100%;
    max-width: 768px;
    min-height: 190px;
    z-index: 1050;
    background: linear-gradient(180deg, #00172a 0%, #00000b 100%);
    border: 1px solid #b625f9;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    .item-more {
        text-decoration: none !important;
        display: flex;
        align-items: center;
        width: calc((100% / 3) - 10px);
        margin: 5px;
        height: 75px;
        border-radius: 5px;
        border: 1px solid #b625f957;

        &:hover {
            background: linear-gradient(0deg, #02ffff -60%, #001326 100%);
        }

        .icon {
            flex: 0 0 50px;
            width: 100%;
            height: auto;
            display: flex;
            align-items: center;
            padding: 5px;

            img {
                width: 100%;
                height: auto;
                object-fit: scale-down;
            }
        }

        .text {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 0 5px;
            text-decoration: none !important;

            .title {
                color: #fff;
                font-family: 'HHBI';
                font-size: 16px;
                font-weight: 600;
            }

            .description {
                text-decoration: none !important;
                font-size: 12px;
                color: #fff;
                line-height: 120%;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3; /* number of lines to show */
                line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }
    }

    @media (max-width: 991px) {
        top: 65px;
        max-width: calc(100% - 20px);
    }
    @media (max-width: 575px) {
        .item-more {
            width: calc((100% / 2) - 10px);
        }
    }
    @media (max-width: 425px) {
        .item-more {
            width: 100%;
        }
    }
}

#newHeader {
    font-family: 'Poppins';
    font-weight: 500;
    // background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    //     rgba(53, 63, 72, 0.8);
    backdrop-filter: blur(7px);
    height: 90px;
    position: relative;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 29;
    // @media (max-width: 1300px) {
    //     height: 69px;
    // }

    .detail-more {
        position: fixed;
        z-index: 1050;
        top: 90px;
        left: 10px;
        width: 100%;
        max-width: 768px;
        min-height: 190px;
        background: linear-gradient(180deg, #00172a 0%, #00000b 100%);
        border: 1px solid #b625f9;
        border-radius: 5px;
        padding: 10px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;

        .item-more {
            text-decoration: none !important;
            display: flex;
            align-items: center;
            width: calc((100% / 3) - 10px);
            margin: 5px;
            height: 75px;
            border-radius: 5px;
            border: 1px solid #b625f957;

            &:hover {
                background: linear-gradient(0deg, #02ffff -60%, #001326 100%);
            }

            .icon {
                flex: 0 0 50px;
                width: 100%;
                height: auto;
                display: flex;
                align-items: center;
                padding: 5px;

                img {
                    width: 100%;
                    height: auto;
                    object-fit: scale-down;
                }
            }

            .text {
                flex: 1 1 auto;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding: 0 5px;
                text-decoration: none !important;

                .title {
                    color: #fff;
                    font-family: 'HHBI';
                    font-size: 16px;
                    font-weight: 600;
                }

                .description {
                    text-decoration: none !important;
                    font-size: 12px;
                    color: #fff;
                    line-height: 120%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3; /* number of lines to show */
                    line-clamp: 3;
                    -webkit-box-orient: vertical;
                }
            }
        }

        @media (max-width: 991px) {
            top: 70px;
            max-width: calc(100% - 20px);
        }
        @media (max-width: 575px) {
            .item-more {
                width: calc((100% / 2) - 10px);
            }
        }
        @media (max-width: 425px) {
            .item-more {
                width: 100%;
            }
        }
    }

    .more-platform {
        @media (max-width: 576px) {
            &.pc {
                display: none;
            }
        }

        button {
            background-color: transparent;
            border: 1px solid #b625f9;
            padding: 0;
            width: 45px;
            height: 45px;
            margin: 0 15px;
            outline: unset !important;
            box-shadow: none !important;

            img {
                width: 100%;
                height: auto;
            }
        }
    }

    .header-logo {
        width: 100%;
        max-width: 130px;
        min-width: 80px;
    }

    .list-navbar {
        height: 100%;
        align-items: center;
        display: none;

        a {
            flex: 1 1 0%;
            max-width: 150px;
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            color: #ffff;
            text-decoration: none;
            position: relative;

            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover,
            &.active {
                background: #3d0ec270;
                color: #b625f9;
                font-weight: 400;
            }
            &.new {
                &::after {
                    content: '';
                    position: absolute;
                    inset: 0;
                    background-image: linear-gradient(
                        -30deg,
                        transparent 0 40%,
                        rgba(255, 255, 255, 0.336),
                        transparent 60% 100%
                    );
                    animation: shining 1.5s infinite ease;
                    background-size: 200%;
                    background-repeat: no-repeat;
                    background-position: 200% 200%;
                    @keyframes shining {
                        to {
                            background-position: -200% -200%;
                        }
                    }
                }
                &.active {
                    &::after {
                        display: none;
                    }
                }
            }
            .popping {
                position: absolute;
                font-family: 'HHBI';
                bottom: 5px;
            }
        }

        @media (min-width: 992px) {
            display: flex;
        }
    }

    .header-balance {
        font-size: 14px;
        color: #f0f0f0;
        padding: 8px 20px;
        border-radius: 12px;
        background: rgba(141, 141, 141, 0.29);
        box-shadow: 0px 0px 4px 0px rgba(188, 188, 188, 0.25);
        backdrop-filter: blur(18.799999237060547px);

        a {
            cursor: pointer;
        }
    }

    .header-control {
        position: relative;

        .ctrl-btn {
            width: 46px;
            height: 46px;
            padding: 0;
            outline: none;
            border: none;
            border-radius: 50%;
            background: none;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .logoutBtn {
            position: absolute;
            z-index: 100;
            left: 10px;
            bottom: -44px;
            background: rgba(0, 255, 255, 0.5);
            padding: 6px 20px;
            color: #fff;
            font-size: 14px;
            border-radius: 30px;
            display: flex;
            align-items: center;
            outline: unset;
            box-shadow: none;
            border: none;

            img {
                width: 18px;
                height: auto;
                margin-left: 10px;
            }

            &:hover {
                background: rgba(0, 255, 255, 0.8);
            }
        }
    }

    .header-avatar {
        width: 46px;
        height: 46px;
        padding: 0;
        outline: none;
        border: none;
        border-radius: 50%;
        background: none;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            border-radius: 50%;
            width: 100%;
            height: 100%;
            transform: scale(1.05);
        }

        .null-avatar {
            width: 80%;
            height: 80%;
            margin: auto;
        }
    }

    .logout {
        position: absolute;
        top: calc(100% + 15px);
        right: 0px;
    }

    .isLogin-nav {
        @media (max-width: 576px) {
            width: 100% !important;
        }
    }

    .total-balance {
        margin: 0;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .menu-btn-view {
        position: relative;
        height: 36px;
        width: 36px;

        .menu-btn {
            outline: unset;
            box-shadow: none;

            img {
                height: 36px;
                width: 36px;
            }
        }
    }

    .list-menu-showed {
        position: fixed;
        z-index: 10000;
        right: 0;
        top: 0;
        width: 100%;
        max-width: 400px;
        height: 100vh;
        // background: rgb(0, 179, 179);
        // backdrop-filter: blur(19px);
        background: #00000b;

        .close-btn {
            color: #b625f9;
        }

        a,
        p {
            color: #000 !important;
            padding: 10px 0;
            margin: 0;
            text-decoration: none;
            font-weight: 400;
        }

        button {
            text-align: left;
            background: none;
            outline: unset;
            box-shadow: none;
            padding: 10px 0;
        }
    }

    .sidebar-menu {
        top: $top-bar-height-mb;
        right: 0;
        bottom: 0;
        width: $side-bar-width;
        // background: #00000b;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        z-index: 2300;
        overflow-x: hidden;
        overflow-y: auto;
        padding: 0 10px;
        // min-height: calc(100vh - 60px);
        height: 100vh;
        @media (max-width: 575px) {
            top: auto;
            bottom: 60px;
            width: 100%;
        }

        .nav-logo {
            display: flex;
            align-items: center;
            min-width: unset;
            max-width: unset;

            img {
                width: 150px;
                height: auto;
            }
        }

        .nav-menu {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;

            .menu-item {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                height: 50px;
                text-decoration: none;
                padding: 0 15px;
                margin: 5px auto;
                cursor: pointer;

                .icons {
                    width: 30px;
                    height: 30px;
                    margin: auto 10px;
                    min-width: 30px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .text {
                    font-size: 17px;
                    color: $top-text-color;
                    text-transform: capitalize;
                }

                &.active {
                    //background: url('~@/assets/images/button/active-menu.jpg');
                }
            }
        }

        .break-point {
            background: #b625f9a4;
            height: 3px;
            width: 100%;
            margin: 20px 0;

            &.break-point-2 {
                height: 1px;
            }
        }

        .break-section {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .name {
                color: #b625f9;
                font-size: 19px !important;
                flex: 0 0 max-content;
            }

            &::before,
            &::after {
                height: 1px;
                display: flex;
                flex: 1 1 auto;
                margin-left: 5px;
                background: #b625f9;
                content: '';
            }

            &::before {
                margin-right: 5px;
            }
        }

        .nav-user {
            width: 100% !important;
            justify-content: flex-start !important;
            flex-direction: column;

            .balances,
            .account {
                width: 100%;
                margin: 10px auto !important;

                &.isLeft {
                    margin: 10px 0 10px 25px !important;
                    width: max-content;
                }
            }

            .account {
                justify-content: flex-start !important;
                padding: 0 15px;

                .icons {
                    margin: 0 10px !important;
                    padding: 3px;

                    img {
                        height: auto !important;
                    }
                }
            }

            .balances {
                width: auto;
                //background: url('~@/assets/images/button/balance.png');
                background-size: 100% 100%;
                height: 40px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 0 0 5px;

                .icons {
                    flex: 0 0 27px;
                    width: 27px;
                    height: 27px;
                    background: black;
                    border-radius: 50%;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .amount {
                    color: #fff;
                    font-size: 15px;
                    padding: 0 7px;
                }

                .buy-btn {
                    flex: 0 0 70px;
                    //background: url('~@/assets/images/button/buy.png');
                    background-size: 100% 100%;
                    width: 70px;
                    color: black;
                    font-weight: 900;
                    font-size: 15px;
                    padding: 0;
                    height: calc(100% - 5px);
                    outline: none !important;
                    border: 0;
                    box-shadow: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    &:focus,
                    &:hover {
                        transform: scale(1.1);
                    }
                }
            }
        }

        @media (max-width: 425px) {
            width: 100%;
        }

        .login-2 {
            margin: 15px auto 0 !important;
            width: 100%;
            max-width: 180px;
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 120px;
            height: 45px;
            text-decoration: none;
            cursor: pointer;
            margin: auto 0 auto 15px;
            background: cyan;
            clip-path: polygon(
                25% 0%,
                75% 0%,
                100% 50%,
                75% 100%,
                25% 100%,
                0% 50%
            );

            .icons {
                width: 35px;
                height: 35px;
                margin: auto 5px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .text {
                color: black;
                font-weight: 900;
                font-size: 20px;
                text-transform: capitalize;
            }

            @media (min-width: 768px) {
                display: none;
            }
        }

        .list-sidebar-item {
            display: flex;
            flex-wrap: wrap;
            max-width: 300px;
            width: 100%;
            margin: 10px auto;

            .sidebar-item {
                flex: 1 1 90px;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 5px 2px;
                max-width: 90px;
                margin: 5px;
                min-height: 60px;
                justify-content: space-between;

                &.active {
                    border: 1px solid #b625f9;
                    border-radius: 3px;
                }

                .icons {
                    width: 35px;
                    height: auto;
                    max-height: 35px;

                    img {
                        width: 100%;
                        object-fit: scale-down;
                        height: 100%;
                    }
                }

                .text {
                    font-size: 11px;
                    color: #fff;
                    text-align: center;
                    &.logout {
                        color: red;
                    }
                }
            }
        }
    }
}
</style>
