const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        error: '',
        success: '',
        maintainers: 0,
        wallet: {
            fee: 0,
        },
        banners: [],
    }),

    getters: {
        Error: (state) => state.error,
        Success: (state) => state.success,
        WalletConfig: (state) => state.wallet,
        Banners: (state) => state.banners,
    },

    actions: {
        reqGetWalletConfig({ commit }) {
            axios.get('/balance/config').then((res) => {
                if (res) {
                    commit('SET_WALLET_CONFIG', res);
                }
            });
        },
        async req_getBanners({ commit }) {
            return axios.get('/system/get-banner').then((res) => {
                if (res && res.results && res.results.length > 0) {
                    commit('SET_BANNERS', res.results);
                }
                return res;
            });
        },
    },
    mutations: {
        SUCCESS_MESSAGE(state, message) {
            state.success = message;
        },
        ERROR_MESSAGE(state, message) {
            state.error = message;
        },
        ERROR_500(state, message) {
            state.status = message;
        },
        SET_WALLET_CONFIG(state, data) {
            state.wallet.fee = data.fee;
        },
        SET_BANNERS(state, data) {
            state.banners = data;
        },
    },
};
