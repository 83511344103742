const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        member: {
            total: 1,
            list: [],
            statistical: {
                totalHGB: 0,
                totalMember: 0,
                totalMemberActive: 0,
                totalUSD: 0,
                totalCommissionInGame: 0,
                totalCommissionWallet: 0,
                totalSaleInGame: 0,
                totalSaleWallet: 0,
            },
            totalRef: 0,
            totalRefF1: 0,
            refs: {},
            commissions: [],
        },
        comissionHistory: {},
    }),

    getters: {
        Member: (state) => state.member,
        CommissionHistory: (state) => state.comissionHistory,
    },

    actions: {
        req_getListMember: ({ commit }, input) => {
            axios
                .get('/user/ref', {
                    params: {
                        search: input.search || null,
                        page: input.page || 1,
                        limit: 4,
                        address: input.address,
                    },
                })
                .then((res) => {
                    commit('SET_LIST_MEMBER', res);
                });
        },
        req_getStatistical: ({ commit }) => {
            axios.get('/user/ref-statistic').then((res) => {
                commit('SET_STATISTICAL', res);
            });
        },
        req_getCommissionStat: ({ commit }) => {
            axios.get('/user/commission-statistic').then((res) => {
                commit('SET_COMMISSION_STAT', res);
            });
        },
        req_getCommissionHistory: ({ commit }, input) => {
            axios
                .get('/user/activity-history', {
                    params: { type: 'commission', limit: 5, page: input.page },
                })
                .then((res) => {
                    commit('SET_HISTORY', res);
                });
        },
    },
    mutations: {
        SET_LIST_MEMBER(state, data) {
            state.member.total = data.totalPages;
            state.member.list = data.results;
            state.member.totalRef = data.totalRef;
            state.member.totalRefF1 = data.totalRefF1;
        },
        SET_STATISTICAL(state, data) {
            state.member.refs = data;
        },
        SET_COMMISSION_STAT(state, data) {
            state.member.commissions = data;
        },
        SET_HISTORY(state, data) {
            state.comissionHistory = data;
        },
    },
};
