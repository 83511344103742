<script>
import { Register } from '@/components/auth';

export default {
    components: { Register },
};
</script>

<template>
    <div class="auth-page">
        <Register class="my-2" />
        <div class="more-info">
            <div class="text-more">
                Already have an account ?
                <router-link :to="{ name: 'Login' }"> Login Now </router-link>
            </div>
        </div>
    </div>
</template>
<style lang="scss">
.auth-page {
    padding: calc(5vh + 20px) 15px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 100vh;
    flex-direction: column;
}
</style>
