import Vue from 'vue';
import Vuex from 'vuex';

// eslint-disable-next-line import/no-cycle
import infoModel from './modules/info';
import authModel from './modules/auth';
import contractModel from './modules/contract';
import coreModel from './modules/core';
import memberModel from './modules/member';
import walletModel from './wallet';
import historyModel from './modules/history';
import Presale from './modules/presale';

// --------------------------------
Vue.use(Vuex);

export default new Vuex.Store({
    state: () => ({
        loadingStatus: false,
        totalSend: 0,
        totalReceive: 0,
        error: '',
        success: '',
        status: 0,
    }),
    getters: {
        LoadingStatus: (state) => state.loadingStatus,
    },
    mutations: {
        onLoad(state) {
            this.commit('StatusLoad', true);
            state.totalSend += 1;
        },
        outLoad(state) {
            state.totalReceive += 1;
            if (state.totalSend <= state.totalReceive) {
                this.commit('StatusLoad', false);
            }
        },
        StatusLoad: (state, data) => {
            if (data) {
                state.loadingStatus = true;
                return;
            }
            state.loadingStatus = false;
            state.totalSend = 0;
            state.totalReceive = 0;
        },
    },
    actions: {},
    modules: {
        info: infoModel,
        auth: authModel,
        contract: contractModel,
        core: coreModel,
        member: memberModel,
        wallet: walletModel,
        history: historyModel,
        preSale: Presale,
    },
});
