<script>
import { mapMutations } from 'vuex';

export default {
    data() {
        return {
            register: {
                sponsor: '',
                address: '',
            },
            sponsor: '',
            rootAdr: '0x67b70A79519476ccC1De67Cc96B77249Bba39802',
            isRequest: false,
        };
    },
    methods: {
        ...mapMutations(['onLoad', 'outLoad']),
        async checkWallet() {
            // return;
            this.onLoad();
            if (!window.ethereum) {
                this.outLoad();
                this.$toastr.e('Please Install Metamask To Join', 'Oops!');
                return false;
            }
            let addrs = [];
            try {
                addrs = await window.ethereum.request({
                    method: 'eth_requestAccounts',
                });
            } catch (error) {
                this.outLoad();
                if (
                    error.message ===
                    'Already processing eth_requestAccounts. Please wait.'
                ) {
                    this.$toastr.e('Please Connect Metamask To Join', 'Oops!');
                    return false;
                }
                this.$toastr.e(error.message, 'Oops!');
                return false;
            }
            if (addrs.length > 0) {
                return addrs[0];
            }
            return null;
        },
        async onRegister() {
            this.onLoad();
            const walletCurrent = await this.checkWallet();
            if (walletCurrent) {
                this.$store.dispatch('auth/CheckRegister', {
                    sponsor:
                        this.sponsor ||
                        '0x67b70A79519476ccC1De67Cc96B77249Bba39802',
                    wallet: walletCurrent,
                });
            } else {
                this.$toastr.e('Please Connect Metamask To Join', 'Oops!');
            }
        },
        async OnActiveContract() {
            this.onLoad();
            let sponsorAddress;
            let sponsorCheck;
            const { sponsor } = this.$route.query;
            const { register } = this.$store.state.contract.managers;

            if (sponsor || this.sponsor) {
                sponsorAddress = this.sponsor || sponsor;
                sponsorCheck = await register.cont.methods
                    .uplineWallet(sponsorAddress)
                    .call();
                console.log('check sponsor', sponsorCheck);
            } else {
                sponsorAddress = '0x0000000000000000000000000000000000000000';
                sponsorCheck = true;
            }

            if (
                sponsorCheck === '0x0000000000000000000000000000000000000000' &&
                sponsorAddress !== this.rootAdr
            ) {
                this.$toastr.e('Sponsor wallet Is not Registered!', 'Oops!');
                this.outLoad();
            } else {
                // if (sponsorAddress === this.rootAdr) {
                //     sponsorAddress =
                //         '0x0000000000000000000000000000000000000000';
                // }
                console.log('check sponsor', sponsorCheck, sponsorAddress);
                const txObj = register.cont.methods
                    .register(sponsorAddress)
                    .encodeABI();
                console.log('check sponsor 1', sponsorCheck, sponsorAddress);
                let gas = 0;
                const usdtFee = await register.cont.methods
                    .register_fee()
                    .call();

                const bnbFee = await register.cont.methods
                    .getBNBPrice(usdtFee)
                    .call();

                try {
                    // Calculate gas
                    gas = await register.cont.methods
                        .register(sponsorAddress)
                        .estimateGas({
                            from: this.$store.state.wallet.addr,
                            value: this.$store.state.contract.web3.provider.utils.toHex(
                                bnbFee,
                            ),
                            gas: 1000000,
                        });
                } catch (error) {
                    console.log(
                        'check sponsor 2',
                        sponsorCheck,
                        sponsorAddress,
                    );
                    this.outLoad();
                    if (
                        error.message ===
                        'Returned error: execution reverted: Your addess already registation'
                    ) {
                        this.$toastr.e(' You Are already registation');
                        this.$router.push({ name: 'Login' });
                        return;
                    }
                    this.$toastr.e(error, 'Oops!');
                    return;
                }

                const gasPrice =
                    await this.$store.state.contract.web3.provider.eth.getGasPrice();
                let res;

                try {
                    res = await window.ethereum.request({
                        method: 'eth_sendTransaction',
                        params: [
                            {
                                from: this.$store.state.wallet.addr,
                                to: register.addr,
                                gas: this.$store.state.contract.web3.provider.utils.toHex(
                                    gas + 50000,
                                ),
                                gasPrice:
                                    this.$store.state.contract.web3.provider.utils.toHex(
                                        gasPrice,
                                    ),
                                value: this.$store.state.contract.web3.provider.utils.toHex(
                                    bnbFee,
                                ),
                                data: this.$store.state.contract.web3.provider.utils.toHex(
                                    txObj,
                                ),
                            },
                        ],
                    });
                    // transaction result
                    const check = setInterval(async () => {
                        const txData =
                            await this.$store.state.contract.web3.provider.eth.getTransactionReceipt(
                                res,
                            );
                        if (txData != null) {
                            clearInterval(check);
                            if (txData.status) {
                                if (!this.isRequest) {
                                    this.isRequest = true;
                                    // send transaction
                                    this.$store.dispatch('auth/Register', {
                                        transaction: txData.transactionHash,
                                    });
                                }
                            } else {
                                this.$toastr.e('Register Failed', 'Oops!! ');
                            }
                        }
                    }, 1000);
                } catch (error) {
                    this.outLoad();
                    this.$toastr.e(error.message, 'Oops!');
                }
            }
        },
    },
    mounted() {
        const { sponsor } = this.$route.query;
        if (sponsor) {
            this.sponsor = sponsor;
        }
    },
    created() {
        this.unsubscribe = this.$store.subscribe((mutation) => {
            switch (mutation.type) {
                case 'auth/REGISTER_SUCCESS':
                    this.outLoad();
                    this.isRequest = false;
                    break;
                default:
                    break;
            }
        });
    },
    beforeDestroy() {
        this.unsubscribe();
    },
};
</script>

<template>
    <div class="auth-box">
        <b-form>
            <div class="form-group row">
                <div class="col-12 p-0">
                    <label for="sponsor">Sponsor</label>
                </div>
                <div class="col-12 p-0">
                    <b-form-input
                        id="sponsor"
                        type="text"
                        v-model.trim="sponsor"
                        placeholder="The wallet address of your referrer"
                    ></b-form-input>
                </div>
            </div>
            <div class="form-group">
                <b-button @click="OnActiveContract"> Active</b-button>
            </div>
        </b-form>
        <div class="more-info">
            <div class="text-more">
                By continuing, you agree to our
                <a href="#" target="_self"> Terms of Use </a>
            </div>
            <div class="breaking"></div>
            <div class="note">
                <div class="title">NOTED:</div>
                <div class="content">
                    <p
                        class="text-left text-warning pl-3 font-weight-bold"
                        style="letter-spacing: 1px"
                    >
                        - Wallet activation successful! If you can't log in to
                        the marketplace, please wait 15 minutes for the bscscan
                        network to confirm the transaction to log into the
                        system
                    </p>
                    <p>
                        - To activate account, you must pay an amount of
                        <span> BNB</span> equivalent to <span>5$</span> (at
                        current exchange rate)
                    </p>
                    <p v-if="false">
                        - You will receive
                        <span>5 HBG tokens, 1 Hero, 1 Land </span> and
                        <span> your reflink </span> after your account is
                        activated.
                    </p>
                    <p>
                        - Commission policy: If a member in your system
                        activated his account successfully, you will receive the
                        amount of <span>BNB</span> equivalent to
                        <span>$2.5</span> (at current exchange rate)
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
