<script>
import { mapGetters } from 'vuex';

import Paginate from '@/components/shared/Paginate.vue';

export default {
    components: {
        Paginate,
    },
    data() {
        return {
            search: {
                page: 1,
                limit: 20,
                type: 'withdraw',
                fromDate: null,
                toDate: null,
            },
            fields: [
                {
                    key: 'amount',
                    sortable: false,
                    label: 'Amount',
                    class: 'text-center min-200',
                },
                {
                    key: 'comment',
                    label: 'Description',
                    sortable: false,
                    class: 'text-center text-capitalize',
                },
                {
                    key: 'transaction',
                    label: 'Hash',
                    sortable: false,
                    class: 'text-center',
                },
                {
                    key: 'time',
                    label: 'TIME',
                    class: 'text-center',
                },
                {
                    key: 'status',
                    label: 'Status',
                    class: 'text-center',
                },
            ],
            fieldsMobile: [
                {
                    key: 'amount',
                    sortable: false,
                    label: 'Amount',
                    class: 'text-center min-200',
                },
                {
                    key: 'comment',
                    label: 'Description',
                    sortable: false,
                    class: 'text-center text-capitalize',
                },
                {
                    key: '#',
                    label: 'More',
                    sortable: false,
                    class: 'text-center',
                },
            ],
            today: null,
            toDateSelected: null,
            currentPage: 1,
            totalPages: 10,
        };
    },
    watch: {
        'search.type': {
            handler(newVal) {
                if (newVal) {
                    this.search.type = newVal;
                    this.$store.dispatch(
                        'history/req_getHistoryWallet',
                        this.search,
                    );
                }
            },
        },
    },
    computed: {
        ...mapGetters({
            History: 'history/History',
            isNetWork: 'contract/isNetWork',
            ListType: 'history/ListType',
            TotalHistory: 'history/TotalHistory',
        }),
        url() {
            if (this.isNetWork === 'testnet') {
                return 'https://testnet.bscscan.com/tx/';
            }
            return 'https://bscscan.com/tx/';
        },
    },
    methods: {
        onChangePage(payload) {
            this.search.page = payload;
            this.$store.dispatch('history/req_getHistoryWallet', this.search);
        },
        onContext(ctx) {
            // The date formatted in the locale, or the `label-no-date-selected` string
            this.formatted = ctx.selectedFormatted;
            // The following will be an empty string until a valid date is entered
            this.selected = ctx.selectedYMD;
        },
        onReset() {
            this.search = {
                page: 1,
                limit: 20,
                type: this.ListType[0].value,
                fromDate: null,
                toDate: null,
            };
            this.onChangePage(1);
        },
    },
    created() {
        this.onChangePage(1);
        this.$store.dispatch('history/req_getHistoryWalletType');
        this.today = new Date();
    },
};
</script>
<template>
    <!-- <b-container class="activity-tab" v-if="ListType"> -->
    <b-container class="activity-tab" v-if="ListType">
        <div class="activity-list">
            <div class="filter-history">
                <label for="">Activity Type</label>
                <div class="group-hist">
                    <select
                        :key="ListType.length"
                        class="form-control select-type"
                        v-model="search.type"
                    >
                        <option
                            :value="item.value"
                            v-for="item in ListType"
                            :key="item.value"
                        >
                            {{ item.text }}
                        </option>
                    </select>
                    <img src="~@/assets/images/icons/drop.png" alt="" />
                </div>
            </div>
            <!-- <b-row class="ml-0 w-100 align-items-center">
                <b-col md="4" sm="6" cols="10">
                    <label for="">From Date </label>
                    <div class="group-hist">
                        <b-form-datepicker
                            id="datepicker"
                            v-model="search.fromDate"
                            class="mb-0 date-form"
                            @context="onContext"
                            :min="toDateSelected"
                            :max="today"
                        ></b-form-datepicker>
                    </div>
                </b-col>
                <b-col md="4" sm="6" cols="10">
                    <label for="">To Date </label>
                    <div class="group-hist">
                        <b-form-datepicker
                            id="datepicker-2"
                            v-model="search.toDate"
                            class="mb-0 date-form"
                            @context="onContext"
                            :disabled="!search.fromDate"
                            :min="search.fromDate"
                            :max="today"
                        ></b-form-datepicker>
                    </div>
                </b-col>
                <b-col md="4">
                    <b-row class="ml-0 w-100 align-items-center">
                        <b-col md="3" sm="2" cols="4">
                            <b-button
                                class="mx-1 filter refresh"
                                @click="onReset()"
                            >
                                <img
                                    src="~@/assets/images/icons/Refresh.png"
                                    alt=""
                                />
                            </b-button>
                        </b-col>
                        <b-col md="9" sm="10" cols="8">
                            <b-button class="bede-btn" @click="onChangePage(1)">
                                <p>Search</p>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row> -->

            <!-- ---------------------------------------------------------------------------------- -->
            <div class="d-none d-lg-block table-responsive mt-4">
                <b-table
                    striped
                    hover
                    :items="History.list"
                    :fields="fields"
                    show-empty
                    thead-class="customer-header"
                >
                    <template #empty>
                        <h4 class="text-center my-3">No Data</h4>
                    </template>
                    <template #cell(transaction)="data">
                        <a
                            v-if="data.item.transaction"
                            target="_blank"
                            class="text-white"
                            :href="`${url}${data.item.transaction}`"
                        >
                            {{
                                data.item.transaction.slice(0, 5) +
                                '...' +
                                data.item.transaction.slice(
                                    data.item.transaction.length - 10,
                                    data.item.transaction.length,
                                )
                            }}
                        </a>
                    </template>
                    <template #cell(time)="data">
                        {{
                            Number.isInteger(data.item.time)? getDateTime(
                                data.item.time * 1000)
                                    : getDateTime3(data.item.time),

                        }}
                    </template>
                    <template #cell(status)="data">
                        <span
                            :class="
                                data.item.status === 'Pending'
                                    ? 'text-warning'
                                    : data.item.status === 'Success'
                                    ? 'text-success'
                                    : 'text-danger'
                            "
                        >
                            {{ data.item.status }}
                        </span>
                    </template>
                </b-table>
            </div>
            <div class="d-lg-none mobile table-responsive mt-4">
                <b-table
                    striped
                    hover
                    :items="History.list"
                    :fields="fieldsMobile"
                    show-empty
                    thead-class="customer-header"
                >
                    <template #empty>
                        <h4 class="text-center my-3">No Data</h4>
                    </template>
                    <template #row-details="{ item }">
                        <div class="details">
                            <div class="d-flex justify-content-between">
                                <div>Transaction:</div>
                                <a
                                    v-if="item.transaction"
                                    target="_blank"
                                    class="text-white"
                                    :href="`${url}${item.transaction}`"
                                >
                                    {{
                                        item.transaction.slice(0, 5) +
                                        '...' +
                                        item.transaction.slice(
                                            item.transaction.length - 10,
                                            item.transaction.length,
                                        )
                                    }}
                                </a>
                            </div>
                            <div class="d-flex justify-content-between">
                                <div>Time of creation:</div>
                                <div>
                                    {{
                                        Number.isInteger(item.time)
                                            ? getDateTime(item.time * 1000)
                                            : getDateTime3(item.time)
                                    }}
                                </div>
                            </div>
                            <div class="d-flex justify-content between">
                                <span
                                    :class="
                                        item.status === 'Pending'
                                            ? 'text-warning'
                                            : item.status === 'Success'
                                            ? 'text-success'
                                            : 'text-danger'
                                    "
                                >
                                    {{ item.status }}
                                </span>
                            </div>
                        </div>
                    </template>
                    <template #cell(#)="row">
                        <b-button
                            size="sm"
                            variant="info"
                            @click="row.toggleDetails"
                            class="px-2 py-0"
                        >
                            {{ row.detailsShowing ? 'Hide' : 'Show' }}
                        </b-button>
                    </template>
                </b-table>
            </div>
        </div>
        <div class="table-paginate">
            <Paginate @current="onChangePage" :totalPages="History.total" />
        </div>
    </b-container>
</template>
<style lang="scss">
.activity-tab {
    label {
        font-family: 'Poppins' !important;
        font-style: normal;
        font-weight: 500;
        font-size: 14px !important;
        line-height: 20px;
        color: #00ffff !important;
    }
    .date-form {
        .form-control {
            background: transparent !important;
        }
    }
    .min-200 {
        min-width: 150px;
        width: max-content;
    }
    .group-hist {
        display: flex;
        justify-content: flex-start;
        margin: 0 0 15px;
        padding-right: 5px;
        position: relative;
        .select-type {
            max-width: 275px;
            height: 48px;
            text-transform: capitalize;
        }
        .b-form-datepicker,
        select {
            max-width: 275px;
            height: 40px;
            background: rgba(33, 41, 48, 0.6);
            color: #fff;
            border-radius: 100px;
            border: none;
            option {
                background: #00000b;
            }

            button {
                &#datepicker,
                &#datepicker-2 {
                    color: #fff;
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;

                    color: #dcdcdc;
                }
            }
            label.form-control {
                color: #fff !important;
                display: flex;
                align-items: center;
            }
        }
        img {
            height: 15px;
            width: auto;
            position: absolute;
            right: 7px;
            bottom: 0;
            top: 0;
            margin: auto;
            display: none;
        }
    }

    .bede-btn {
        max-width: 221px;
        height: 50px;
    }
    .refresh {
        color: #000;
        font-weight: 600;
        font-size: 18px;
        display: flex;
        background: transparent;
        align-items: center;
        justify-content: center;
        text-transform: capitalize;
        width: 40px;
        height: 40px;
        padding: 0;
        border: 1px solid #00ffff;
        border-radius: 100%;
        @media (max-width: 575px) {
            width: 35px;
            height: 35px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: scale-down;
        }
    }
    .filter-history {
        flex: 1;
        // max-width: 255px;
        min-width: 220px;
        @media (max-width: 575px) {
            max-width: 300px;
            min-width: 100%;
        }
    }

    .title {
        color: #00ffff;
        font-family: 'Helvetica';
        font-size: clamp(1.2em, 4vw, 1.8em);
        padding-bottom: 10px;
        position: relative;
        letter-spacing: 0.6px;
        width: max-content;
        margin: 0 0 25px;
        font-weight: 900;
        &::after {
            content: '';
            bottom: 0;
            left: 0;
            width: calc(105% + 10px);
            height: 2px;
            background: #00ffff;
            position: absolute;
            font-weight: 600;
        }
    }
    .table-responsive {
        .customer-header {
            background-color: transparent;
            text-align: center;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;

            color: #a6a6a6;
        }

        .table th,
        .table td {
            border: none;
        }

        table {
            thead {
                tr {
                    th {
                        white-space: nowrap;
                    }
                }
            }

            tbody {
                tr {
                    height: 70px;
                    border-radius: 12px;
                    background: rgba(62, 18, 96, 0.46);
                    &:nth-child(odd) {
                        background: rgba(62, 18, 96, 1);
                    }

                    td {
                        background: none;
                        height: 100%;
                        padding: 0.75rem;
                        vertical-align: middle;
                        color: #fff;
                        font-weight: 500;

                        > span {
                            height: 70px;
                            display: flex;
                            padding: 0 0.75rem;
                            align-items: center;
                            margin-bottom: 0;
                            justify-content: center;
                            backdrop-filter: blur(15.899999618530273px);
                        }
                    }
                }
            }
        }
        &.mobile {
            tbody {
                tr {
                    td {
                        .details {
                            padding: 0.5rem;
                            .address {
                                max-width: 200px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                background-color: #86198f;
                                color: #fff;
                                border-radius: 7px;
                                transition: 0.2s;
                                &:active {
                                    background-color: #4a044e;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .table-statistical {
        // border: 1px solid #00ffff;
        max-width: 500px;
        margin: 15px auto;
        .table-item {
            // background: linear-gradient(180deg, #00172a 0%, #00000b 100%);
            height: 40px;
            padding: 0 0.75rem;
            vertical-align: middle;
            color: #fff;
            font-weight: 600;
            display: flex;
            font-family: 'Helvetica';
            justify-content: space-between;

            &:nth-of-type(even) {
                box-shadow: inset 0px 0px 2005px rgba(0, 255, 255, 0.2);
            }
            .left {
                flex: 0 0 calc(100% - 300px);
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }
            .right {
                flex: 0 0 250px;
                border-left: 1px solid #00ffff;
                display: flex;
                align-items: center;
                justify-content: center;
                @media (min-width: 992px) {
                    justify-content: flex-start;
                    text-align: left;
                    padding-left: 5%;
                }
            }
            @media (min-width: 768px) and (max-width: 991px) {
                flex-direction: column;
                height: auto;
                padding: 5px 0;
                .right,
                .left {
                    flex: unset;
                    width: 100%;
                    justify-content: center;
                    border: 0;
                }
            }
            @media (max-width: 575px) {
                flex-direction: column;
                height: auto;
                padding: 5px 0;
                .right,
                .left {
                    flex: unset;
                    width: 100%;
                    justify-content: center;
                    border: 0;
                }
            }
        }
    }
}
</style>
