/* eslint-disable import/no-cycle */
/* eslint-disable no-shadow */
import store from '@/store';

const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        address: null,
        info: {
            email: null,
            address: null,
        },
        balance: 0,
    }),

    getters: {
        Address: (state) => state.address,
        UserInfo: (state) => state.info,
        Balance: (state) => state.balance,
    },

    actions: {
        req_getInfo: ({ commit }) => {
            axios.get('user/info').then((res) => {
                if (res) {
                    commit('GET_INFO_SUCCESS', res);
                }
            });
        },
    },
    mutations: {
        SET_ADDRESS: (state, data) => {
            state.address = data.address;
            console.log(data.address);
        },
        GET_INFO_SUCCESS: (state, data) => {
            state.info = data;
            console.log(data, state.info);
            state.balance =
                Math.round(parseFloat(data.uConCoin) * 10000) / 10000;
            if (state.info.unknownUser) state.unKnown = true;
            store.commit('wallet/setupWallet', data.address);
        },
    },
};
